import type { ApiJulaModelsCartCartResponseModel } from 'models/api';
import { is } from 'utils/helpers';

export function isAnonUserWithRequiredDetails(
	cart: ApiJulaModelsCartCartResponseModel | undefined,
) {
	return (
		is.defined(cart) &&
		is.defined(cart.customerAddress) &&
		is.truthy(cart.customerAddress.postalCode) &&
		is.truthy(cart.email)
	);
}

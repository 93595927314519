/**
 * Customize next-localization.
 *
 * - Default to returning the translation key when translation is missing.
 * - Add a custom `tEmpty` with the original behavior of returning empty string.
 */

import { I18n, useI18n as useI18nNext } from 'next-localization';

// Re-export to have a common entrypoint.
export { I18nProvider } from 'next-localization';

interface I18nExtended<T> extends I18n<T> {
	/**
	 * Retrieve a translation segment for the current language, returning the key
	 * if a translation is missing.
	 */
	t: I18n<T>['t'];

	/**
	 * Retrieve a translation segment for the current language, returning an
	 * empty string if a translation is missing.
	 */
	tEmpty: I18n<T>['t'];
}

export const useI18n = useI18nNext as <T>() => I18nExtended<T>;

const rosettaOpts = { en: {} };

// Export a i18n provider to be used outside of React-components
export const i18n = I18n(rosettaOpts) as I18nExtended<typeof rosettaOpts>;

const originalT = i18n.t;

i18n.t = (key, ...props) => originalT(key, ...props) || String(key);

i18n.tEmpty = originalT;

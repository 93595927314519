import cookie from 'cookie';
import { assign, createMachine, send, spawn } from 'xstate';
import { pure } from 'xstate/lib/actions';

import { sendToast } from 'components/Toast';
import { gtmMachine } from 'state-machines/gtm';
import { pushToGTM } from 'utils/GoogleTagManager';
import { sendGlobalEvent } from 'utils/helpers';

import { requestSessionEndpoint, requestTokenData } from './user.services';
import type {
	UserMachineContext,
	UserMachineEvents,
	UserMachineServices,
} from './user.types';

export const userMachine = createMachine(
	{
		id: 'user',
		initial: 'initialisation',
		context: {
			// Child actors should technically be nullable but they're created on
			// entry so pretend they're not to keep usage simpler. Using ts-ignore
			// will somehow disable typing for every context prop so use any instead.
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			gtmActor: null as any,
			skipAuth: false,
			isAuthenticated: false,
			sessionEndpointFailedRequests: 0,
		},
		tsTypes: {} as import('./user.machine.typegen').Typegen0,
		schema: {} as {
			context: UserMachineContext;
			events: UserMachineEvents;
			services: UserMachineServices;
		},
		states: {
			initialisation: {
				tags: ['initialisation'],
				entry: 'lock',
				always: [
					{
						target: 'skipAuth',
						cond: 'shouldSkipAuth',
					},
					{
						target: 'checkTokenStatus',
					},
				],
			},
			skipAuth: {
				description:
					'if we are in the experience editor the context variable skip auth is true and we do not continue with the auth flow',
				type: 'final',
			},
			checkTokenStatus: {
				tags: ['initialisation'],
				description:
					'send request to /session endpoint to get token status, if 201 we reload the page and end up here again',
				invoke: {
					id: 'requestTokenStatus',
					src: 'requestTokenStatus',
					onDone: [
						{ target: '#delayedBrowserRefresh', cond: 'shouldRefreshBrowser' },
						{
							target: 'checkUserStatus',
							actions: 'setTokenExpiry',
						},
					],
					onError: {
						target: '#sessionEndpointRequestFailed',
					},
				},
			},
			checkUserStatus: {
				tags: ['initialisation'],
				description:
					'we introspect the token and check if the user is logged in or not if logged in we expect 200 and token data that we sed to authUser, if user has accepted  jula pro invte were taken to org details page, if not logged in we expect 204 and no data',
				invoke: {
					id: 'checkUserStatus',
					src: 'checkUserStatus',
					onDone: [
						{
							target: '#authenticatedUser',
							cond: 'isAuthenticatedUser',
							actions: 'setUserData',
						},
						{ target: '#authenticatedAnon' },
					],
				},
			},
			authenticatedAnon: {
				tags: ['authenticated', 'authenticatedAnon'],
				id: 'authenticatedAnon',
				entry: 'unlock',
				initial: 'idle',
				invoke: {
					src: 'listenForWindowEvent',
				},
				states: {
					idle: {
						on: {
							REFRESH: {
								target: 'refreshingToken',
							},
						},
					},
					refreshingToken: {
						entry: 'lock',
						invoke: {
							id: 'getRefreshToken',
							src: 'requestTokenRefresh',
							onDone: [
								{
									target: '#delayedBrowserRefresh',
									cond: 'shouldRefreshBrowser',
								},
								{
									target: 'idle',
									actions: ['setTokenExpiry', 'unlock'],
								},
							],
							onError: {
								target: '#sessionEndpointRequestFailed',
							},
						},
					},
				},
			},
			authenticatedUser: {
				tags: ['authenticated', 'authenticatedUser'],
				id: 'authenticatedUser',
				entry: ['unlock', 'spawnGTMActor'],
				initial: 'checkRedirectToOrganizationDetails',
				invoke: {
					src: 'listenForWindowEvent',
				},
				states: {
					checkRedirectToOrganizationDetails: {
						always: [
							{
								target: 'idle',
								cond: 'hasAcceptedJulaProInvite',
								actions: 'redirectToOrganizationDetails',
							},
							{
								target: 'idle',
							},
						],
					},
					idle: {
						on: {
							REFRESH: {
								target: 'refreshingToken',
							},
						},
					},
					refreshingToken: {
						entry: 'lock',
						invoke: {
							id: 'getRefreshToken',
							src: 'requestTokenRefresh',
							onDone: [
								{
									target: '#delayedBrowserRefresh',
									cond: 'shouldRefreshBrowser',
								},
								{
									target: 'idle',
									actions: [
										'setTokenExpiry',
										'unlock',
										'sendLoginRenewalToGTMMachine',
									],
								},
							],
							onError: {
								target: '#sessionEndpointRequestFailed',
							},
						},
					},
					logout: {
						id: 'logout',
						tags: ['logout'],
						entry: 'pushLogoutToGTM',
						invoke: {
							src: 'logout',
							onDone: { actions: 'refreshAndReturnToRoot' },
							onError: {
								target: 'idle',
								actions: 'showLogoutErrorToast',
							},
						},
					},
				},
				on: {
					LOGOUT: {
						target: '#logout',
					},
				},
			},
			delayedBrowserRefresh: {
				id: 'delayedBrowserRefresh',
				after: {
					2000: { actions: 'refreshBrowser' },
				},
			},
			sessionEndpointRequestFailed: {
				id: 'sessionEndpointRequestFailed',
				entry: 'incSessionEndpointFailedRequests',
				always: [
					{
						target: 'showFailure',
					},
				],
			},
			showFailure: {
				after: {
					RETRY_DELAY: { target: 'initialisation' },
				},
			},
			errorVerifying: {
				always: [
					{
						target: 'showFailure',
					},
				],
			},
		},

		on: {
			LOGOUT: {
				target: '#logout',
			},
		},
	},
	{
		guards: {
			isAuthenticatedUser: (context, event) => event.data.statusCode === 200,
			shouldSkipAuth: (context) => context.skipAuth,
			shouldRefreshBrowser: (_, event) => event.data.statusCode === 201,
			hasAcceptedJulaProInvite: (context) => {
				const cookies = cookie.parse(document.cookie);
				return Boolean(
					cookies.redirectToOrganizationDetailsDone !== 'true' &&
						context.userData?.julaProAcceptInvite,
				);
			},
		},
		actions: {
			setUserData: assign({
				userData: (_, event) => event.data.tokenData,
			}),
			unlock: (context, _event) => {
				sendGlobalEvent('unlock');
			},
			lock: (context, _event) => {
				sendGlobalEvent('lock');
			},
			spawnGTMActor: assign({
				gtmActor: (context) =>
					// 	'The handling of the voyado id is a special case for now as it is not available in all customer token. Related documentation can be found here: https://jula.atlassian.net/wiki/spaces/JWE/pages/2618589193/Login+Events+-+Voyado+ID',
					spawn(
						gtmMachine.withContext({
							shouldPushLogin: false,
							shouldPushLoginRenewal: false,
							voyadoId: context.userData?.customer_ext_voyado,
						}),
						'gtmMachine',
					),
			}),

			setTokenExpiry: (_, event) => {
				window.localStorage.setItem(
					'tokenExpiry',
					event.data.tokenData.tokenExpiry.toString(),
				);
			},
			// Action to refresh the browser
			refreshBrowser: (context, event) => {
				const search = new URLSearchParams(window.location.search);
				const returnPath = search.get('returnPath');
				if (returnPath) {
					window.location.assign(returnPath);
				} else {
					window.location.reload();
				}
			},
			refreshAndReturnToRoot: (_context) => {
				window.location.assign('/');
			},
			redirectToOrganizationDetails: (context, event) => {
				document.cookie = cookie.serialize(
					'redirectToOrganizationDetailsDone',
					'true',
				);
				window.location.href = `${context.organizationDetailUrl}?accepted_invite`;
			},
			pushLogoutToGTM: () => {
				pushToGTM({
					type: 'logout',
				});
			},

			sendLoginRenewalToGTMMachine: pure((context) =>
				send(
					{ type: 'LOGIN_RENEWAL' },
					{ to: (toContext: typeof context) => toContext.gtmActor },
				),
			),
			incSessionEndpointFailedRequests: assign({
				sessionEndpointFailedRequests: (context) =>
					context.sessionEndpointFailedRequests + 1,
			}),
			showLogoutErrorToast: (_context) => {
				sendToast('logout_error', 'error');
			},
		},
		services: {
			requestTokenRefresh: (context, _event) => requestSessionEndpoint(),
			requestTokenStatus: (context, _event) => requestSessionEndpoint(),
			logout: async (context, _event) => {
				const res = await fetch('/api/signin/session/?logout=true');
				if (!res.ok) throw new Error('logout failed');
				return res;
			},
			checkUserStatus: (context, _event) => requestTokenData(),
			listenForWindowEvent: () => (sendEvent) => {
				const refresh = () => {
					sendEvent({ type: 'REFRESH' });
				};
				const logout = () => {
					sendEvent({ type: 'LOGOUT' });
				};

				window.addEventListener('refresh-token', refresh);
				window.addEventListener('logout', logout);
				return () => {
					window.removeEventListener('refresh-token', refresh);
					window.removeEventListener('logout', logout);
				};
			},
		},
		delays: {
			// Delay for attempting to get or check token, one second per failed
			// attempt up to a maximum of 5 seconds.
			RETRY_DELAY: (context) =>
				Math.min(context.sessionEndpointFailedRequests || 1, 5) * 1000,
		},
	},
);

import { useEffect, useState } from 'react';

import { type Breakpoint, breakpoints } from 'styles/media';
import { is } from 'utils/helpers';

/**
 * Check and listen to changes for a media query.
 *
 * NOTE! The initial value is `undefined` since the client's state is
 * unknown on the server.
 */
export function useMediaQuery(query: string) {
	const [matches, setMatches] = useState<boolean | undefined>(undefined);

	useEffect(() => {
		const media = window.matchMedia(query);

		// Set the true initial value when on the client.
		setMatches(media.matches);

		// Watch for changes.
		const listener = (e: MediaQueryListEvent) => {
			setMatches(e.matches);
		};
		media.addEventListener('change', listener);
		return () => {
			media.removeEventListener('change', listener);
		};
	}, [query]);

	return matches;
}

export function useMinWidth(width: Breakpoint | number) {
	return useMediaQuery(
		is.number(width)
			? `(min-width: ${width}px)`
			: `(min-width: ${breakpoints[width]}px)`,
	);
}

export function useMaxWidth(width: Breakpoint | number) {
	return useMediaQuery(
		is.number(width)
			? `(max-width: ${width}px)`
			: `(max-width: ${breakpoints[width] - 1}px)`,
	);
}

export function usePrefersReducedMotion() {
	return useMediaQuery('(prefers-reduced-motion: reduce)');
}

// TODO: can be removed when InspirationLandscapeOld and InspirationSquare are gone
export function useIsSmallOrLarger() {
	return useMediaQuery(`(min-width: ${breakpoints.sm}px)`);
}

// TODO: can be removed with InspirationLandscapeOld
export function useIsMediumOrLarger() {
	return useMediaQuery(`(min-width: ${breakpoints.md}px)`);
}

// TODO: check for hooks only used in ProductAnimation (the old one that is to be removed), can be removed at the same time

export function useIsMedium() {
	return useMediaQuery(
		`(min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg - 1}px)`,
	);
}
export function useIsSmall() {
	return useMediaQuery(
		`(min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.md - 1}px)`,
	);
}

export function useIsLargeOrLarger() {
	return useMediaQuery(`(min-width: ${breakpoints.lg}px)`);
}
export function useIsMediumOrSmaller() {
	return useMediaQuery(`(max-width: ${breakpoints.md}px)`);
}

export function useIsXSmall() {
	return useMediaQuery(`(max-width: ${breakpoints.sm - 1}px)`);
}

import { assign, createMachine, sendParent } from 'xstate';

import type { ApiJulaModelsCartCartErrorModel } from 'models/api';

import type {
	Context,
	Events,
	SelectContactReferenceEvent,
} from './contactReferences.types';

export const contactReferencesMachine = createMachine(
	{
		id: 'contactReferences',
		initial: 'idle',
		schema: {
			context: {} as Context,
			events: {} as Events,
		},
		tsTypes: {} as import('./contactReferences.machine.typegen').Typegen0,
		context: {
			contactReferencesErrorTypes: ['ContactReferenceNotSelected'],
		},
		states: {
			idle: {
				on: {
					CART_UPDATED: {
						target: 'idle',
						actions: 'updateData',
					},
					SELECT_CONTACT_REFERENCE: {
						target: 'loading',
						actions: ['saveData', 'updateDataOptimistic'],
					},
				},
			},
			loading: {
				on: {
					CART_UPDATED: {
						target: 'idle',
						actions: 'updateData',
					},
				},
			},
		},
		on: {
			RESET_TO_OLD: {
				target: 'idle',
				actions: 'resetToOldContactPerson',
			},
		},
	},
	{
		actions: {
			updateData: assign({
				contactReferences: (context, event) =>
					event?.value?.contactReferences || context?.contactReferences,
				selectedContactReference: (context, event) =>
					event?.value?.selectedContactReference ||
					context?.selectedContactReference,
				errors: (context, event) => {
					let contactReferencesErrors: ApiJulaModelsCartCartErrorModel[] = [];

					if (event?.value?.errorList) {
						contactReferencesErrors = event?.value?.errorList.filter(
							(error: ApiJulaModelsCartCartErrorModel) =>
								context.contactReferencesErrorTypes.includes(
									error.type as string,
								),
						);
					}

					return contactReferencesErrors;
				},
			}),

			updateDataOptimistic: assign({
				selectedContactReference: (context, _event) => {
					const event = _event as SelectContactReferenceEvent;
					return event?.value || context?.selectedContactReference;
				},
				oldSelectedContactReference: (_, _event) => {
					const event = _event as SelectContactReferenceEvent;
					return event?.value;
				},
			}),

			resetToOldContactPerson: assign({
				selectedContactReference: (context) =>
					context.oldSelectedContactReference,
			}),

			saveData: sendParent((_, _event) => {
				const event = _event as SelectContactReferenceEvent;
				return {
					type: 'UPDATE_CART',
					selectedContactReference: event.value,
				};
			}),
		},
	},
);

// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconCartDisabled(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="m2.72 4 .92 10c0 .64.1 1.28.29 1.9L14.83 5h-10l-.2-2.09a1 1 0 0 0-1-.91h-2a1 1 0 0 0-1 1 1 1 0 0 0 1 1h1.1Z" />
			<path d="M21.41 5h-.93l1.84-1.84a1 1 0 0 0-1.41-1.41L1.4 21.25a1 1 0 1 0 1.41 1.41l1.92-1.92a3 3 0 0 0 5.89-.74c0-.34-.06-.68-.17-1h4.36a3.01 3.01 0 0 0 2.82 4c1.64 0 3-1.35 3-3s-1.36-3-3-3H8.49l1.32-1.32 10.06-.63a1 1 0 0 0 .92-.8l1.6-8.05a.7.7 0 0 0 .03-.2 1 1 0 0 0-1-1h-.01Z" />
		</svg>
	);
}
IconCartDisabled.displayName = 'IconCartDisabled';

// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconSupport(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M21 12.22a9 9 0 1 0-18 .06A1.968 1.968 0 0 0 2 14v2a2.006 2.006 0 0 0 2 2h1v-6.1a7 7 0 0 1 14 0V19h-8v2h8a2.006 2.006 0 0 0 2-2v-1.22a1.849 1.849 0 0 0 1-1.64v-2.3a1.841 1.841 0 0 0-1-1.62z" />
			<circle cx="1" cy="1" r="1" transform="translate(8 12)" />
			<circle cx="1" cy="1" r="1" transform="translate(14 12)" />
			<path d="M18 11.03a6.039 6.039 0 0 0-11.98 1.42 8.075 8.075 0 0 0 4.86-5.89A8.037 8.037 0 0 0 18 11.03z" />
		</svg>
	);
}
IconSupport.displayName = 'IconSupport';

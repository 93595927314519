// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconPinterest(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M12.335 0C5.587 0 2 4.216 2 8.812c0 2.131 1.222 4.79 3.178 5.633.558.245.484-.054.964-1.844a.417.417 0 0 0-.1-.417c-2.8-3.153-.546-9.635 5.9-9.635 9.327 0 7.584 12.582 1.623 12.582a2.164 2.164 0 0 1-2.326-2.631c.439-1.733 1.3-3.6 1.3-4.845 0-3.148-4.811-2.681-4.811 1.49a4.944 4.944 0 0 0 .467 2.155S6.65 17.4 6.36 18.539a15.779 15.779 0 0 0 .114 5.318.163.163 0 0 0 .3.073 18.718 18.718 0 0 0 2.548-4.678c.191-.685.973-3.465.973-3.465a4.29 4.29 0 0 0 3.588 1.668c4.714 0 8.121-4.04 8.121-9.053C21.984 3.6 17.765 0 12.335 0z" />
		</svg>
	);
}
IconPinterest.displayName = 'IconPinterest';

import { stringifyUrl } from 'query-string';
import { assign, createMachine, send, spawn } from 'xstate';
import { pure } from 'xstate/lib/actions';

import { ACCOUNT_RETURN_PATH_QUERY_PARAM } from 'layouts/LayoutAccount';
import type {
	ConnectErrorDescription,
	LoginMethod,
} from 'models/connect/oauth';
import {
	AUTH_CODE_FAILURE,
	AUTH_CODE_SUCCESS,
	OIDC_REDIRECT_URL_AUTH_CODE_QUERY_PARAM,
	OIDC_REDIRECT_URL_CUSTOMER_TYPE_QUERY_PARAM,
	OIDC_REDIRECT_URL_ERROR_DESCRIPTION_QUERY_PARAM,
} from 'pages/api/signin/oidc';
import { gtmMachine } from 'state-machines/gtm';
import { sendGlobalEvent } from 'utils/helpers';
import {
	getRegisteredTokenOauthParams,
	getUnregisteredTokenOauthParams,
} from 'utils/jula-connect-settings';

import type {
	AuthCustomerType,
	AuthMachineContext,
	AuthMachineEvents,
	AuthMachineServices,
	OauthParams,
} from './auth.types';

const openAUthWindow = async (iframeSrc: string | undefined) =>
	new Promise<Window | null>((resolve, reject) => {
		if (!iframeSrc || iframeSrc === '') reject();

		const authWindow = window.open(iframeSrc, '_self');

		if (!authWindow) reject();

		resolve(authWindow);
	});

const SAVED_AUTH_PARAMS = 'SAVED_AUTH_PARAMS';
type SavedAuthParams = {
	customerType: AuthCustomerType;
	loginMethod: LoginMethod;
	oauthParams: OauthParams;
};
export const shouldUseGuiFull = (loginMethod: string) =>
	['bank_id_fi', 'bank_id_no'].includes(loginMethod);

export const getAccountReturnPath = () => {
	const search = new URLSearchParams(window.location.search);
	return search.get(ACCOUNT_RETURN_PATH_QUERY_PARAM) || undefined;
};

export const authMachine = createMachine(
	{
		tsTypes: {} as import('./auth.machine.typegen').Typegen0,
		schema: {} as {
			context: AuthMachineContext;
			events: AuthMachineEvents;
			services: AuthMachineServices;
		},
		id: 'authUser',
		initial: 'startupIntialisation',
		predictableActionArguments: true,
		context: {
			authWindow: null,
			// Child actors should technically be nullable but they're created on
			// entry so pretend they're not to keep usage simpler. Using ts-ignore
			// will disable typing for every context property so use any instead.
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			gtmActor: null as any,
		},
		states: {
			startupIntialisation: {
				after: {
					1: [
						{
							target: 'handleRedirectLogin',
							cond: 'hasRedirectLoginParams',
							actions: [
								'setRedirectLoginQueryParams',
								'clearRedirectUrlParams',
							],
						},
						{
							target: 'resumeLoginBankIdSE',
							cond: 'shouldResumeLoginBankIdSE',
							actions: 'getSavedAuthParams',
						},
						{
							target: 'awaitAuthAction',
						},
					],
				},
			},
			handleRedirectLogin: {
				description: 'Handle oidc redirect login',
				initial: 'determineWhatToOpen',
				states: {
					determineWhatToOpen: {
						always: [
							{
								cond: 'shouldOpenProSignupForm',
								target: 'checkShouldCompleteTokenRequest',
								actions: 'openJulaProSignupForm',
							},
							{
								cond: 'shouldOpenClubSignupForm',
								target: 'checkShouldCompleteTokenRequest',
								actions: 'openJulaClubSignupForm',
							},
							{
								cond: 'shouldOpenLoginPopoverClubStaff',
								target: 'checkShouldCompleteTokenRequest',
								actions: 'openLoginPopoverClubStaff',
							},
							{
								cond: 'shouldOpenLoginPopoverPro',
								target: 'checkShouldCompleteTokenRequest',
								actions: 'openLoginPopoverPro',
							},
						],
					},
					checkShouldCompleteTokenRequest: {
						always: [
							{
								cond: 'hasAuthCode',
								target: '#completeTokenRequest',
							},
							{
								cond: 'hasError',
								target: '#awaitAuthAction',
								actions: ['setJulaConnectErrorMessage'],
							},
							{ target: '#awaitAuthAction' },
						],
					},
				},
			},
			resumeLoginBankIdSE: {
				description:
					'special handling for bank id se app redirects on iphone where the browser tab might be killed',
				exit: ['clearSavedAuthParams'],
				always: [
					{
						target: '#loadJulaConnectGui',
						cond: 'shouldOpenProSignupForm',
						actions: 'openJulaProSignupForm',
					},
					{
						target: '#loadJulaConnectGui',
						cond: 'shouldOpenClubSignupForm',
						actions: 'openJulaClubSignupForm',
					},
					{
						target: '#loadJulaConnectGui',
						cond: 'shouldOpenLoginPopoverClubStaff',
						actions: 'openLoginPopoverClubStaff',
					},
					{
						target: '#loadJulaConnectGui',
						cond: 'shouldOpenLoginPopoverPro',
						actions: 'openLoginPopoverPro',
					},
				],
			},
			awaitAuthAction: {
				tags: 'awaitingAuthAction',
				id: 'awaitAuthAction',
				on: {
					SELECT_LOGIN_CLUB_STAFF: {
						target: 'checkSaveAuthParams',
						actions: 'setRegisteredClubStaffTokenAuthParams',
					},

					SELECT_LOGIN_PRO: {
						target: 'checkSaveAuthParams',
						actions: 'setRegisteredProTokenAuthParams',
					},

					SELECT_AUTH_JULA_CLUB_BECOME_MEMBER: {
						target: 'checkSaveAuthParams',
						actions: 'setUnregisteredTokenAuthParams',
					},
					SELECT_AUTH_JULA_PRO_BECOME_MEMBER: {
						target: 'checkSaveAuthParams',
						actions: 'setUnregisteredTokenAuthParams',
					},
				},
			},
			checkSaveAuthParams: {
				tags: ['isLoadingGuiFull', 'isLoadingGuiIframe'],
				always: [
					{
						target: 'loadJulaConnectGui',
						cond: 'shouldSaveAuthParams',
						actions: 'saveAuthParams',
					},
					{
						target: 'loadJulaConnectGui',
					},
				],
			},
			loadJulaConnectGui: {
				id: 'loadJulaConnectGui',
				initial: 'determineGuiType',
				tags: ['isLoadingGuiFull', 'isLoadingGuiIframe'],
				states: {
					determineGuiType: {
						always: [
							{
								target: 'loadGuiFull',
								cond: 'shouldOpenGuiFull',
							},
							{
								target: 'loadGuiIframe',
							},
						],
					},
					loadGuiFull: {
						tags: ['isLoadingGuiFull'],
						invoke: {
							id: 'openAuthWindow',
							src: 'openAuthWindow',
							onDone: {
								target: '#handleAuthCallbackAndCleanup',
								actions: 'setAuthWindowRef',
							},
							onError: {
								target: '#awaitAuthAction',
								actions: ['resetIframeSrc', 'closeAuthWindow', 'clearError'],
							},
						},
					},
					loadGuiIframe: {
						id: 'loadGuiIframe',
						tags: ['isLoadingGuiIframe'],
						on: {
							LOGIN_FORM_LOADED: '#handleAuthCallbackAndCleanup',
						},
					},
				},
			},

			handleAuthCallbackAndCleanup: {
				id: 'handleAuthCallbackAndCleanup',
				tags: ['isLoadingGuiFull'],
				exit: ['closeAuthWindow', 'clearSavedAuthParams'],
				initial: 'waitingForAuthCallback',
				invoke: {
					src: 'listenForIframeMessage',
				},
				states: {
					waitingForAuthCallback: {
						description:
							'Waiting for registered token auth code, unregistered token response or possible user abort',
						on: {
							AUTH_CODE_RESPONSE: {
								target: 'completeTokenRequest',
								actions: 'setAuthCode',
							},
							AUTH_CODE_RESPONSE_FAILURE: {
								target: '#awaitAuthAction',
								actions: [
									'setConnectErrorDescription',
									'setJulaConnectErrorMessage',
								],
							},
							ABORT_AUTHENTICATION: {
								target: '#awaitAuthAction',
							},
							ABORT_AUTHENTICATION_AND_REGISTER: {
								target: '#awaitAuthAction',
								actions: ['closeLoginPopover', 'openBecomeMemberJulaClub'],
							},
							ABORT_AUTHENTICATION_AND_CONTACT_CUSTOMER_SERVICE: {
								target: '#awaitAuthAction',
								actions: ['closeLoginPopover', 'openCustomerContactForm'],
							},
						},
					},
					completeTokenRequest: {
						id: 'completeTokenRequest',
						tags: ['isLoadingGuiIframe'],
						invoke: {
							id: 'completeTokenRequest',
							src: 'completeTokenRequest',
							onDone: [
								{
									cond: 'hasUnregisteredToken',
									target: '#awaitAuthAction',
									actions: 'setUnregisteredToken',
								},
								{
									target: 'delayedBrowserRefresh',
									actions: ['spawnGTMActor', 'sendLoginToGTMMachine'],
								},
							],
							onError: {
								target: '#awaitAuthAction',
								actions: 'setCompleteRequestError',
							},
						},
					},
					delayedBrowserRefresh: {
						tags: ['delayedBrowserRefresh', 'isLoadingGuiIframe'],
						id: 'delayedBrowserRefresh',
						after: {
							2000: { actions: 'refreshBrowser' },
						},
					},
				},
			},
		},
		on: {
			RELOAD_IFRAME: {
				target: '#awaitAuthAction',
				actions: 'clearSavedAuthParams',
			},
			RESET_UNREGISTERED_TOKEN: {
				actions: 'resetUnregisteredToken',
			},
			GTM_LOGIN_PUSH_DONE: {
				actions: ['refreshBrowser'],
			},
		},
	},
	{
		guards: {
			hasRedirectLoginParams: (_context, _event) => {
				const search = new URLSearchParams(window.location.search);
				return (
					search.has(OIDC_REDIRECT_URL_AUTH_CODE_QUERY_PARAM) ||
					search.has(OIDC_REDIRECT_URL_ERROR_DESCRIPTION_QUERY_PARAM)
				);
			},
			hasUnregisteredToken: (_context, event) =>
				Boolean(event.data.unregisteredToken),
			shouldSaveAuthParams: (context) => context.loginMethod === 'bank_id_se',
			shouldResumeLoginBankIdSE: (_context) => {
				if (typeof window !== 'undefined') {
					return Boolean(window.localStorage.getItem(SAVED_AUTH_PARAMS));
				}
				return false;
			},
			shouldOpenGuiFull: (context) => shouldUseGuiFull(context.loginMethod!),
			shouldOpenProSignupForm: (context) =>
				context.customerType === 'Unregistered_Pro',
			shouldOpenClubSignupForm: (context) =>
				context.customerType === 'Unregistered_Club',
			shouldOpenLoginPopoverClubStaff: (context) =>
				context.customerType === 'Club Staff',
			shouldOpenLoginPopoverPro: (context) => context.customerType === 'Pro',
			hasError: (context) => Boolean(context.connectErrorDescription),
			hasAuthCode: (context) => Boolean(context.authCode),
		},
		actions: {
			spawnGTMActor: assign({
				gtmActor: (_context) =>
					// 	'The handling of the voyado id is a special case for now as it is not available in all customer token. Related documentation can be found here: https://jula.atlassian.net/wiki/spaces/JWE/pages/2618589193/Login+Events+-+Voyado+ID',
					spawn(
						gtmMachine.withContext({
							shouldPushLogin: false,
							shouldPushLoginRenewal: false,
						}),
						'gtmMachine',
					),
			}),
			sendLoginToGTMMachine: pure((_context) =>
				send(
					{ type: 'LOGIN' },
					{ to: (context: typeof _context) => context.gtmActor },
				),
			),
			refreshBrowser: (_context) => {
				const accountReturnPath = getAccountReturnPath();
				if (accountReturnPath) {
					window.location.assign(accountReturnPath);
				} else {
					window.location.reload();
				}
			},
			openJulaProSignupForm: (_context) =>
				sendGlobalEvent('global-popover-open-global-link', {
					globalLink: 'signUpJulaPro',
				}),
			openJulaClubSignupForm: (_context) =>
				sendGlobalEvent('global-popover-open-global-link', {
					globalLink: 'signUpJulaClub',
				}),
			openLoginPopoverClubStaff: (_context) =>
				sendGlobalEvent('open-login', {
					loginType: 'LoginJulaClub',
				}),
			openLoginPopoverPro: (_context) =>
				sendGlobalEvent('open-login', {
					loginType: 'LoginJulaPro',
				}),
			closeLoginPopover: (_context) => sendGlobalEvent('close-login'),
			openBecomeMemberJulaClub: (_context) =>
				sendGlobalEvent('global-popover-open-global-link', {
					globalLink: 'signUpJulaClub',
				}),
			openCustomerContactForm: (_context) =>
				sendGlobalEvent('global-popover-open-global-link', {
					globalLink: 'contactForm',
				}),
			setAuthWindowRef: assign({
				authWindow: (_context, event) => event.data,
			}),
			closeAuthWindow: assign({
				authWindow: (_context) => {
					if (_context.authWindow) _context.authWindow.close();

					return null;
				},
			}),
			saveAuthParams: (context) => {
				if (typeof document !== 'undefined') {
					window.localStorage.setItem(
						SAVED_AUTH_PARAMS,
						JSON.stringify({
							customerType: context.customerType,
							loginMethod: context.loginMethod,
							oauthParams: context.oauthParams,
						}),
					);
				}
			},
			getSavedAuthParams: assign({
				customerType: (_context) => {
					if (typeof document !== 'undefined') {
						const params = window.localStorage.getItem(SAVED_AUTH_PARAMS);
						if (params) {
							const savedAuthParams = JSON.parse(params) as SavedAuthParams;
							return savedAuthParams.customerType;
						}
					}
					return undefined;
				},
				loginMethod: (_context) => {
					if (typeof document !== 'undefined') {
						const params = window.localStorage.getItem(SAVED_AUTH_PARAMS);
						if (params) {
							const savedAuthParams = JSON.parse(params) as SavedAuthParams;
							return savedAuthParams.loginMethod;
						}
					}
					return undefined;
				},
				oauthParams: (_context) => {
					if (typeof document !== 'undefined') {
						const params = window.localStorage.getItem(SAVED_AUTH_PARAMS);
						if (params) {
							const savedAuthParams = JSON.parse(params) as SavedAuthParams;
							return savedAuthParams.oauthParams;
						}
					}
					return undefined;
				},
			}),
			clearSavedAuthParams: (_context) => {
				localStorage.removeItem(SAVED_AUTH_PARAMS);
			},
			setConnectErrorDescription: assign({
				connectErrorDescription: (_context, event) => event.errorDescription,
			}),
			setRedirectLoginQueryParams: assign({
				connectErrorDescription: (_context) => {
					const search = new URLSearchParams(window.location.search);
					return (
						(search.get(
							OIDC_REDIRECT_URL_ERROR_DESCRIPTION_QUERY_PARAM,
						) as ConnectErrorDescription) || undefined
					);
				},
				authCode: (_context) => {
					const search = new URLSearchParams(window.location.search);
					return (
						search.get(OIDC_REDIRECT_URL_AUTH_CODE_QUERY_PARAM) || undefined
					);
				},
				customerType: (_context) => {
					const search = new URLSearchParams(window.location.search);
					return search.get(OIDC_REDIRECT_URL_CUSTOMER_TYPE_QUERY_PARAM) as
						| AuthCustomerType
						| undefined;
				},
			}),
			// Sets the error
			setJulaConnectErrorMessage: assign({
				errorMessage: (context) => {
					switch (context.connectErrorDescription) {
						case 'x-UserError':
							return 'account_login_error_profile_locked_text';
						case 'x-UserNotFound':
							return context.customerType === 'Pro'
								? 'account_login_error_not_registered_pro'
								: 'account_login_error_not_registered_club';
						case 'x-TooManyLogins':
							return 'account_login_error_too_many_times_text';

						case 'x-UserCancel':
							return undefined;

						default:
							return 'account_login_error_try_again_text';
					}
				},
			}),
			setCompleteRequestError: assign({
				errorMessage: (_context) => 'account_login_error_try_again_text',
			}),
			clearError: assign({
				errorMessage: (_context) => undefined,
			}),
			setUnregisteredTokenAuthParams: assign({
				customerType: (_context, event): AuthCustomerType =>
					event.type === 'SELECT_AUTH_JULA_CLUB_BECOME_MEMBER'
						? 'Unregistered_Club'
						: 'Unregistered_Pro',
				loginMethod: (_context, event) => event.loginMethod,
				oauthParams: (_context, event) =>
					getUnregisteredTokenOauthParams({
						loginMethod: event.loginMethod,
						customerType:
							event.type === 'SELECT_AUTH_JULA_CLUB_BECOME_MEMBER'
								? 'Unregistered_Club'
								: 'Unregistered_Pro',
					}),
			}),
			// Gets the params needed for JulaConnect iframe embed
			setRegisteredClubStaffTokenAuthParams: assign({
				customerType: (_context): AuthCustomerType => 'Club Staff',
				loginMethod: (_context, event) => event.loginMethod,
				oauthParams: (_context, event) =>
					getRegisteredTokenOauthParams({
						customerType: 'Club Staff',
						loginMethod: event.loginMethod,
						customerNumber: event.customerNumber,
					}),
			}),
			setRegisteredProTokenAuthParams: assign({
				customerType: (_context): AuthCustomerType => 'Pro',
				loginMethod: (_context, event) => event.loginMethod,
				oauthParams: (_context, event) =>
					getRegisteredTokenOauthParams({
						customerType: 'Pro',
						loginMethod: event.loginMethod,
						customerNumber: event.customerNumber,
					}),
			}),
			// "Reset" the iframe src by appending a timestamp
			// which will force the iframe to refresh
			resetIframeSrc: assign({
				oauthParams: (context) => ({
					...context.oauthParams,
					iframeSrc: stringifyUrl({
						url: context.oauthParams?.iframeSrc || '',
						query: {
							_retry: Date.now(),
						},
					}),
				}),
			}),

			setUnregisteredToken: assign({
				unregisteredToken: (_context, event) => event.data.unregisteredToken,
			}),
			resetUnregisteredToken: assign({
				unregisteredToken: (_context) => undefined,
			}),
			setAuthCode: assign({
				authCode: (_context, event) => event.code,
			}),
			clearRedirectUrlParams: (_context) => {
				const url = new URL(window.location.href);

				url.searchParams.delete(OIDC_REDIRECT_URL_AUTH_CODE_QUERY_PARAM);
				url.searchParams.delete(
					OIDC_REDIRECT_URL_ERROR_DESCRIPTION_QUERY_PARAM,
				);
				url.searchParams.delete(OIDC_REDIRECT_URL_CUSTOMER_TYPE_QUERY_PARAM);
				url.searchParams.delete(
					OIDC_REDIRECT_URL_ERROR_DESCRIPTION_QUERY_PARAM,
				);
				window.history.replaceState({}, '', url.toString());
			},
		},
		services: {
			completeTokenRequest: (context) =>
				new Promise(async (resolve, reject) => {
					const response = await fetch('/api/signin/complete/', {
						body: JSON.stringify({ code: context.authCode }),
						method: 'POST',
					});
					if (response.ok) {
						const data = await response.json();
						if (response.status === 200) {
							resolve({
								unregisteredToken: data.unregisteredToken,
								status: response.status,
							});
						}
						resolve({ status: response.status });
					} else {
						reject();
					}
				}),

			// Subscribe to messages coming from iframes
			listenForIframeMessage: () => (sendCallback) => {
				if (typeof window === 'undefined') {
					return undefined;
				}

				const listener = (event: MessageEvent) => {
					if (event.data === 'link_register_member') {
						sendCallback('ABORT_AUTHENTICATION_AND_REGISTER');
					} else if (event.data === 'link_customer_service') {
						sendCallback('ABORT_AUTHENTICATION_AND_CONTACT_CUSTOMER_SERVICE');
					}
					if (event.origin !== window.location.origin) {
						return;
					}

					switch (event.data.type) {
						case AUTH_CODE_SUCCESS: {
							sendCallback({
								type: 'AUTH_CODE_RESPONSE',
								code: event.data.code,
							});

							break;
						}
						case AUTH_CODE_FAILURE: {
							sendCallback({
								type: 'AUTH_CODE_RESPONSE_FAILURE',
								errorDescription: event.data.errorDescription,
							});

							break;
						}
						default:
						// No default
					}
				};

				window.addEventListener('message', listener, false);

				return () => {
					window.removeEventListener('message', listener);
				};
			},
			openAuthWindow: (context) =>
				openAUthWindow(context.oauthParams?.iframeSrc),
		},
	},
);

// Splitting the hook into a bunch of tiny functions won't make it more readable.

import { useCallback, useRef, useState } from 'react';
import type {
	ChangeEventHandler,
	FocusEventHandler,
	InputHTMLAttributes,
	KeyboardEventHandler,
} from 'react';

import { HTMLAttributesWithRef } from 'types';
import { findNewIndex } from 'utils/collection';

const COMBOBOX_KEYS = [
	'ArrowDown',
	'ArrowUp',
	'ArrowLeft',
	'ArrowRight',
	'Enter',
	'Escape',
];

interface Params {
	baseId: string;
	clearSelectedOnBlur?: boolean;
	initialInputValue?: string;
	onOptionSelect?: (selectedOption: HTMLElement) => void;
	onOpen?: () => void;
	openOnFocus?: boolean;
}

/**
 * Handle keyboard interaction for a combobox widget (text input + listbox).
 *
 * - https://www.w3.org/WAI/ARIA/apg/patterns/combobox/
 * - https://react-spectrum.adobe.com/blog/building-a-combobox.html
 */
export function useCombobox<ListboxElementT extends HTMLElement>({
	baseId,
	clearSelectedOnBlur = true,
	initialInputValue = '',
	onOptionSelect,
	onOpen,
	openOnFocus = true,
}: Params) {
	const listboxId = `${baseId}-listbox`;
	const listboxRef = useRef<ListboxElementT>(null);

	const [isOpen, setIsOpen] = useState(false);
	const [selectedOptionId, setSelectedOptionId] = useState('');
	const [inputValue, setInputValueState] = useState(initialInputValue);

	const open = useCallback(() => {
		if (!isOpen) {
			setIsOpen(true);
			if (onOpen) {
				onOpen();
			}
		}
	}, [isOpen, onOpen]);

	const close = useCallback(() => {
		if (isOpen) {
			setIsOpen(false);
			setSelectedOptionId('');
		}
	}, [isOpen]);

	const clearSelectedOption = useCallback(() => {
		setSelectedOptionId('');
	}, []);

	const setInputValue = useCallback(
		(value: string) => {
			setInputValueState(value);
			clearSelectedOption();
		},
		[clearSelectedOption],
	);

	const onInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
		if (!listboxRef.current || !COMBOBOX_KEYS.includes(e.key)) {
			return;
		}

		const options = [
			...listboxRef.current.querySelectorAll<HTMLElement>('[role="option"]'),
		];
		const currentIndex = selectedOptionId
			? options.findIndex((el) => el.id === selectedOptionId)
			: -1;
		const currentOption = currentIndex === -1 ? null : options[currentIndex];

		switch (e.key) {
			case 'Enter': {
				if (currentOption) {
					e.preventDefault();
					close();
					if (onOptionSelect) {
						onOptionSelect(currentOption);
					}
				}
				break;
			}

			// Clear selection if there is one, otherwise clear the input and close.
			case 'Escape':
				if (isOpen) {
					close();
				} else {
					setInputValue('');
				}
				break;

			// Clear selected option when navigating the input text.
			case 'ArrowLeft':
			case 'ArrowRight':
				setSelectedOptionId('');
				break;

			// Select the previous or next item, looping around at the top or bottom.
			case 'ArrowUp':
			case 'ArrowDown': {
				if (!isOpen) {
					open();
					// Alt + down just opens without selecting.
					if (e.altKey && e.key === 'ArrowDown') {
						return;
					}
				}
				const nextIndex = findNewIndex(
					options,
					currentIndex,
					e.key === 'ArrowUp' ? 'prev' : 'next',
				);
				const nextOption = options[nextIndex];
				if (nextOption) {
					e.preventDefault();
					setSelectedOptionId(nextOption.id);
					nextOption.scrollIntoView({ block: 'nearest' });
				}
				break;
			}

			default:
			// Do nothing
		}
	};

	const onInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
		setInputValue(e.target.value);
		open();
	};
	const onInputFocus: FocusEventHandler<HTMLInputElement> = () => {
		open();
	};
	const onInputBlur: FocusEventHandler<HTMLInputElement> = () => {
		clearSelectedOption();
	};

	const inputProps: InputHTMLAttributes<HTMLInputElement> = {
		role: 'combobox',
		value: inputValue,
		onKeyDown: onInputKeyDown,
		onChange: onInputChange,
		onFocus: openOnFocus ? onInputFocus : undefined,
		onBlur: clearSelectedOnBlur ? onInputBlur : undefined,
		'aria-activedescendant': selectedOptionId || undefined,
		'aria-autocomplete': 'none',
		'aria-controls': listboxId,
		'aria-expanded': isOpen,
	} as const;
	const listboxProps: HTMLAttributesWithRef<ListboxElementT> = {
		role: 'listbox',
		id: listboxId,
		ref: listboxRef,
	} as const;

	return {
		isOpen,
		inputValue,
		setInputValue,
		clearSelectedOption,
		selectedOptionId,
		onInputBlur,
		inputProps,
		listboxRef,
		listboxProps,
		openCombobox: open,
		closeCombobox: close,
	};
}

import { Customer } from 'models/customer';
import fetchData, { API_URL } from 'utils/fetchData';
import { createUrl } from 'utils/url';

export const requestCustomerApi = (
	email: string | undefined,
): Promise<Customer> => {
	let apiPath = '';
	let method = '';

	apiPath = createUrl(`${API_URL}Customer/search`, { email });
	method = 'GET';

	return new Promise<Customer>((resolve, reject) => {
		const headers = { method };
		fetchData(apiPath, headers)
			.then((data) => resolve(data as unknown as Customer))
			.catch((error) => reject(error));
	});
};

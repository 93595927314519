import { createMachine } from 'xstate';

export type GlobalFetchLockMachineGlobalEvents = {
	lock: undefined;
	unlock: undefined;
};

export const globalFetchLockMachine = createMachine(
	{
		id: 'globalFetchLock',
		initial: 'locked',
		context: {},
		states: {
			locked: {
				invoke: { src: 'listenForWindowEvents' },
				on: {
					UNLOCK: 'unlocked',
				},
			},
			unlocked: {
				invoke: { src: 'listenForWindowEvents' },
				on: {
					LOCK: 'locked',
				},
			},
		},
	},
	{
		services: {
			listenForWindowEvents: () => (send) => {
				const lock = () => {
					send('LOCK');
				};
				const unlock = () => {
					send('UNLOCK');
				};

				window.addEventListener('lock', lock);
				window.addEventListener('unlock', unlock);
				return () => {
					window.removeEventListener('lock', lock);
					window.removeEventListener('unlock', unlock);
				};
			},
		},
	},
);

// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconWatchLater(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M12 2C6.51 2 2 6.51 2 12s4.51 10 10 10 10-4.51 10-10A10.08 10.08 0 0 0 12 2Zm3.55 13.8-4.08-2.51a.99.99 0 0 1-.48-.85V7.75a.77.77 0 0 1 .76-.75c.41 0 .75.34.75.75v4.45l3.84 2.31a.76.76 0 1 1-.79 1.29Z" />
		</svg>
	);
}
IconWatchLater.displayName = 'IconWatchLater';

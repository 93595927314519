// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconSettings(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M19.14 12.94a7.074 7.074 0 0 0 .06-.94 5.777 5.777 0 0 0-.07-.94l2.03-1.58a.491.491 0 0 0 .12-.61l-1.92-3.32a.488.488 0 0 0-.59-.22l-2.39.96a7.064 7.064 0 0 0-1.62-.94l-.36-2.54a.484.484 0 0 0-.48-.41h-3.84a.474.474 0 0 0-.47.41l-.36 2.54a7.22 7.22 0 0 0-1.62.94l-2.39-.96a.477.477 0 0 0-.59.22L2.74 8.87a.455.455 0 0 0 .12.61l2.03 1.58a5.563 5.563 0 0 0-.02 1.88l-2.03 1.58a.491.491 0 0 0-.12.61l1.92 3.32a.488.488 0 0 0 .59.22l2.39-.96a7.064 7.064 0 0 0 1.62.94l.36 2.54a.492.492 0 0 0 .48.41h3.84a.466.466 0 0 0 .47-.41l.36-2.54a6.859 6.859 0 0 0 1.62-.94l2.39.96a.477.477 0 0 0 .59-.22l1.92-3.32a.463.463 0 0 0-.12-.61zM12 15.6a3.6 3.6 0 1 1 3.6-3.6 3.611 3.611 0 0 1-3.6 3.6z" />
		</svg>
	);
}
IconSettings.displayName = 'IconSettings';

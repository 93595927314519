// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconThumbDown(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M23 3h-4v12h4zM1 14a2.006 2.006 0 0 0 2 2h6.31l-.95 4.57-.03.32a1.505 1.505 0 0 0 .44 1.06L9.83 23l6.58-6.59A1.955 1.955 0 0 0 17 15V5a2.006 2.006 0 0 0-2-2H6a1.987 1.987 0 0 0-1.84 1.22l-3.02 7.05A1.976 1.976 0 0 0 1 12z" />
		</svg>
	);
}
IconThumbDown.displayName = 'IconThumbDown';

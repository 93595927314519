// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconInstagram(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M23.935 7.056a8.77 8.77 0 0 0-.558-2.912A6.148 6.148 0 0 0 19.865.633a8.792 8.792 0 0 0-2.914-.558C15.669.014 15.261 0 12.003 0S8.336.014 7.056.07a8.772 8.772 0 0 0-2.911.558 5.856 5.856 0 0 0-2.129 1.388A5.909 5.909 0 0 0 .633 4.14a8.793 8.793 0 0 0-.558 2.911C.014 8.336 0 8.744 0 12s.014 3.666.07 4.946a8.769 8.769 0 0 0 .558 2.912A6.147 6.147 0 0 0 4.14 23.37a8.793 8.793 0 0 0 2.911.56c1.28.056 1.688.07 4.946.07s3.666-.014 4.946-.07a8.767 8.767 0 0 0 2.911-.558 6.139 6.139 0 0 0 3.512-3.512 8.8 8.8 0 0 0 .558-2.912c.056-1.28.07-1.688.07-4.946s-.003-3.666-.059-4.946zm-2.161 9.8a6.578 6.578 0 0 1-.413 2.227 3.981 3.981 0 0 1-2.279 2.279 6.6 6.6 0 0 1-2.227.412c-1.266.056-1.646.07-4.848.07s-3.587-.014-4.848-.07a6.575 6.575 0 0 1-2.227-.412 3.693 3.693 0 0 1-1.381-.897 3.731 3.731 0 0 1-.9-1.378 6.6 6.6 0 0 1-.4-2.227c-.056-1.266-.07-1.646-.07-4.848s.014-3.587.07-4.848a6.575 6.575 0 0 1 .412-2.227 3.648 3.648 0 0 1 .9-1.379 3.726 3.726 0 0 1 1.378-.9 6.606 6.606 0 0 1 2.223-.408c1.266-.056 1.646-.07 4.848-.07s3.587.014 4.848.07a6.578 6.578 0 0 1 2.227.413 3.69 3.69 0 0 1 1.378.9 3.73 3.73 0 0 1 .9 1.379 6.605 6.605 0 0 1 .413 2.227c.056 1.266.07 1.646.07 4.848s-.018 3.572-.075 4.838zm0 0" />
			<path d="M12.003 5.837a6.165 6.165 0 1 0 6.165 6.165 6.167 6.167 0 0 0-6.165-6.165zm0 10.165a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0 0" />
			<path d="M19.851 5.593a1.439 1.439 0 1 1-1.439-1.439 1.439 1.439 0 0 1 1.439 1.439zm0 0" />
		</svg>
	);
}
IconInstagram.displayName = 'IconInstagram';

import { assign, createMachine } from 'xstate';
import { pure } from 'xstate/lib/actions';

import { sendToast } from 'components/Toast';
import { pushToGTM } from 'utils/GoogleTagManager';
import { is } from 'utils/helpers';

import { requestWishlistApi } from './wishlist.services';
import type {
	WishlistMachineContext,
	WishlistMachineEvents,
	WishlistMachineServices,
} from './wishlist.types';

export const wishlistMachine = createMachine(
	{
		id: 'wishlistMachine',
		initial: 'idle',
		type: 'parallel',
		tsTypes: {} as import('./wishlist.machine.typegen').Typegen0,
		predictableActionArguments: true,
		schema: {
			context: {} as WishlistMachineContext,
			events: {} as WishlistMachineEvents,
			services: {} as WishlistMachineServices,
		},
		context: {
			additionalSalesIsOpen: false,
			buttonState: {
				state: 'idle',
			},
			showToast: false,
		},
		states: {
			loadSharedWishlist: {
				id: 'loadSharedWishlist',
				initial: 'idle',
				states: {
					idle: {
						on: {
							FETCH_WISHLIST_BY_ID: 'loadingSharedWishlist',
						},
					},
					loadingSharedWishlist: {
						invoke: {
							id: 'getSharedWishlist',
							src: 'getSharedWishlist',
							onDone: {
								actions: 'setSharedWishlistData',
								target: 'idle',
							},
							onError: {
								target: 'idle',
							},
						},
					},
				},
			},
			loadPersonalWishlist: {
				id: 'loadPersonalWishlist',
				initial: 'initialLoadWishlist',
				states: {
					initialLoadWishlist: {
						tags: 'loading',
						on: {
							FETCH_MINI_WISHLIST: 'loadingMiniWishlist',
							FETCH_WISHLIST: {
								target: 'loadingWishlist',
								actions: 'setStoreId',
							},
							SHARE_WISHLIST: {
								target: 'shareWishlist',
							},
						},
					},
					idle: {
						on: {
							FETCH_MINI_WISHLIST: 'loadingMiniWishlist',
							FETCH_WISHLIST: {
								target: 'loadingWishlist',
								actions: 'setStoreId',
							},
							SHARE_WISHLIST: {
								target: 'shareWishlist',
							},
						},
					},
					loadingMiniWishlist: {
						tags: 'loading',
						on: {
							FETCH_WISHLIST: {
								target: 'loadingWishlist',
								actions: 'setStoreId',
							},
						},
						invoke: {
							id: 'getMiniWishlist',
							src: 'getMiniWishlist',
							onDone: {
								actions: 'setWishListProductQuantity',
								target: 'success',
							},
						},
					},
					loadingWishlist: {
						tags: 'loading',
						invoke: {
							id: 'getWishlist',
							src: 'getWishlist',
							onDone: {
								actions: 'setWishlistData',
								target: 'success',
							},
							onError: {
								target: 'error',
							},
						},
					},
					shareWishlist: {
						invoke: {
							id: 'shareWishlist',
							src: 'shareWishlist',
							onDone: [
								{
									actions: 'setShareData',
									target: 'shareSuccess',
								},
							],
							onError: {
								target: 'error',
							},
						},
					},
					changingVariantQty: {
						tags: 'loading',
						invoke: {
							id: 'changeVariantQty',
							src: 'changeVariantQtyInWishlist',
							onDone: {
								actions: 'setWishlistData',
								target: 'updateGTM',
							},
							onError: {
								target: 'error',
							},
						},
					},
					settingVariantQty: {
						tags: 'loading',
						invoke: {
							id: 'setVariantQty',
							src: 'updateVariantQtyInWishlist',
							onDone: {
								actions: 'setWishlistData',
								target: 'updateGTM',
							},
							onError: {
								target: 'error',
							},
						},
					},
					removingVariant: {
						tags: 'loading',
						invoke: {
							id: 'removeVariant',
							src: 'removeVariantFromWishlist',
							onDone: {
								actions: 'setWishlistData',
								target: 'success',
							},
							onError: {
								target: 'error',
							},
						},
					},
					removingAll: {
						tags: 'loading',
						invoke: {
							id: 'removeAll',
							src: 'removeAllFromWishlist',
							onDone: {
								actions: 'resetWishlistData',
								target: 'success',
							},
							onError: {
								target: 'error',
							},
						},
					},
					shareSuccess: {
						tags: ['wishlistWasShared'],
						after: {
							100: {
								target: 'success',
							},
						},
					},
					success: {
						on: {
							FETCH_WISHLIST: {
								target: 'loadingWishlist',
								actions: 'setStoreId',
							},
							SHARE_WISHLIST: {
								target: 'shareWishlist',
							},
							CHANGE_VARIANT_QTY: {
								target: 'changingVariantQty',
								actions: 'setGTMData',
							},
							SET_VARIANT_QTY: {
								target: 'settingVariantQty',
								actions: 'setGTMData',
							},
							REMOVE_VARIANT: 'removingVariant',
							REMOVE_ALL: 'removingAll',
						},
					},
					error: {
						on: {
							FETCH_WISHLIST: {
								target: 'loadingWishlist',
								actions: 'setStoreId',
							},
						},
					},
					updateGTM: {
						entry: 'updateGTM',
						exit: 'resetGTMData',
						always: {
							target: 'success',
						},
					},
				},
			},
			addOneToWishlist: {
				id: 'addOneToWishlist',
				initial: 'idle',
				states: {
					idle: {
						on: {
							ADD_ONE_TO_WISHLIST: {
								target: 'addingOneToWishlist',
								actions: ['setShowToast', 'setGTMData'],
							},
							CLEAR_ADDITIONAL_SALES_PRODUCTS: {
								actions: 'clearAdditionalSalesProducts',
							},
						},
					},
					addingOneToWishlist: {
						entry: 'setButtonStateLoading',
						exit: 'resetShowToast',
						invoke: {
							id: 'addOneToWishlist',
							src: 'addOneToWishList',
							onDone: [
								{
									target: 'updateGTM',
									cond: 'hasAdditionalSales',
									actions: [
										'setButtonState',
										'setAdditionalSalesProducts',
										'setWishListProductQuantity',
									],
								},
								{
									target: 'updateGTM',
									cond: 'showToast',
									actions: [
										'setButtonState',
										'showToastMessage',
										'setWishListProductQuantity',
									],
								},
								{
									target: 'updateGTM',
									cond: 'isSuccess',
									actions: [
										'setButtonStateSuccess',
										'setWishListProductQuantity',
									],
								},
								{
									target: 'idle',
									actions: [
										'setButtonStateFailed',
										'setWishListProductQuantity',
									],
								},
							],
							onError: [
								{
									target: 'idle',
									cond: 'showToast',
									actions: 'setButtonStateFailed',
								},
								{
									target: 'idle',
									actions: 'setButtonStateFailed',
								},
							],
						},
					},
					updateGTM: {
						entry: 'updateGTM',
						exit: 'resetGTMData',
						always: {
							target: 'idle',
						},
					},
				},
			},
		},
	},
	{
		services: {
			getWishlist: (_context, event) =>
				requestWishlistApi({
					requestType: 'fetchWishlist',
					urlParams: { storeId: event.storeId },
				}),
			getSharedWishlist: (context, event) =>
				requestWishlistApi({
					requestType: 'fetchWishlistById',
					wishListId: event.id,
					urlParams: { storeId: event.storeId },
				}),
			getMiniWishlist: () =>
				requestWishlistApi({ requestType: 'fetchMiniWishlist' }),
			shareWishlist: () => requestWishlistApi({ requestType: 'shareWishlist' }),
			removeAllFromWishlist: () =>
				requestWishlistApi({ requestType: 'removeAll' }),
			removeVariantFromWishlist: (context, event) =>
				requestWishlistApi({
					requestType: 'removeVariant',
					variantId: event.variantId,
					urlParams: { storeId: context.storeId },
				}),
			updateVariantQtyInWishlist: (context, event) =>
				requestWishlistApi({
					requestType: 'setVariantQty',
					variantId: event.variantId,
					urlParams: {
						qty: event.qty,
						storeId: context.storeId,
					},
				}),
			changeVariantQtyInWishlist: (context, event) =>
				requestWishlistApi({
					requestType: 'changeVariantQty',
					variantId: event.variantId,
					urlParams: { qty: event.qty, storeId: context.storeId },
				}),
			addOneToWishList: (_context, event) =>
				requestWishlistApi({
					requestType: 'addOneToWishList',
					variantId: event.variantId,
					urlParams: {
						returnAccessories: event.requestAdditionalSales ? 'true' : 'false',
					},
				}),
		},
		guards: {
			hasAdditionalSales: (_context, event) =>
				is.arrayWithLength(event.data.accessories),
			showToast: (context) => context.showToast,
			isSuccess: (_context, event) => event.data.success,
		},
		actions: {
			setShowToast: assign({
				showToast: (_context, event) => event.showToast,
			}),
			resetShowToast: assign({
				showToast: (_context) => false,
			}),
			setWishListProductQuantity: assign({
				productQuantity: (context, event) => event.data.totalQty,
			}),
			setWishlistData: assign({
				wishlistData: (context, event) => event.data,
				productQuantity: (context, event) => event.data.totalQty,
			}),
			resetWishlistData: assign({
				wishlistData: (context, event) => undefined,
				productQuantity: (context, event) => undefined,
			}),
			setSharedWishlistData: assign({
				sharedWishlistData: (context, event) => event.data,
			}),
			setShareData: assign({
				wishlistShareId: (context, event) => event.data,
			}),
			setStoreId: assign({
				storeId: (_context, event) => event.storeId,
			}),
			setButtonStateLoading: assign({
				buttonState: (_context, event) => ({
					state: 'loading' as const,
					buttonId: event.variantId,
				}),
			}),
			setButtonState: pure((context, event) =>
				event.data.success ? 'setButtonStateSuccess' : 'setButtonStateFailed',
			),
			setButtonStateSuccess: assign({
				buttonState: (context) => ({
					state: 'success' as const,
					buttonId: context.buttonState.buttonId,
				}),
			}),
			setButtonStateFailed: assign({
				buttonState: (context) => ({
					state: 'failure' as const,
					buttonId: context.buttonState.buttonId,
				}),
			}),
			setAdditionalSalesProducts: assign({
				additionalSales: (context, event) => ({
					products: event.data.accessories,
					toastText: event.data.message,
				}),
				additionalSalesIsOpen: (context) => true,
			}),
			clearAdditionalSalesProducts: assign({
				additionalSales: (context) => undefined,
				additionalSalesIsOpen: (context) => false,
			}),
			showToastMessage: (_, event) => {
				sendToast(
					event.data.message || '',
					event.data.success ? 'success' : 'error',
				);
			},
			updateGTM: (context, event) => {
				if (!context.GTMData) return;
				pushToGTM({
					type: context.GTMData.type,
					payload: { ...context.GTMData.payload },
				});
			},
			setGTMData: assign({
				GTMData: (context, event) => event.GTMData,
			}),
			resetGTMData: assign({
				GTMData: (_context) => undefined,
			}),
		},
	},
);

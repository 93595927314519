import { assign, createMachine } from 'xstate';

import { sendToast } from 'components/Toast';
import type { ApiJulaModelsCartCartResponseModel } from 'models/api';
import { requestCheckoutApi } from 'state-machines/checkout';
import { getDiscountCodesDuplicate } from 'utils/business-logic';
import { pushToGTM } from 'utils/GoogleTagManager';
import { is, sendGlobalEvent } from 'utils/helpers';
import { pathStartsWith } from 'utils/url';

import { addMultipleToCart } from './cart.services';
import type {
	CartMachineContext,
	CartMachineEvents,
	CartMachineServices,
} from './cart.types';

export const cartMachine = createMachine(
	{
		context: {
			id: '',
			totalQty: 0,
			productAddedSuccessfully: false,
			additionalSalesIsOpen: false,
			additionalSalesToastText: '',
			purchaseButtonState: {
				state: 'idle',
			},
			showToast: false,
			requestAdditionalSales: false,
		},
		id: 'cartMachine',
		initial: 'initialLoadCart',
		schema: {
			context: {} as CartMachineContext,
			events: {} as CartMachineEvents,
			services: {} as CartMachineServices,
		},
		tsTypes: {} as import('./cart.machine.typegen').Typegen0,
		predictableActionArguments: true,
		states: {
			initialLoadCart: {
				tags: 'loadingCart',
				after: {
					1: [
						{
							cond: 'isInCart',
							target: 'getCart',
						},
						{
							target: 'getCartMini',
						},
					],
				},
			},
			idle: {
				id: 'idle',
				on: {
					GET_CART: {
						target: 'getCart',
					},
					ADD_ONE_FROM_BUY_BUTTON: {
						target: 'addOneFromBuyButton',
					},
					CLEAR_ADDITIONAL_SALES_PRODUCTS: {
						actions: 'clearAdditionalSalesProducts',
					},
					ADD_REMOVE_VARIANT: {
						actions: ['setGTMData'],
						target: 'addRemoveCart',
					},
					ADD_MULTIPLE_VARIANTS_MINI: {
						actions: ['setGTMData'],
						target: 'addMultipleVariantsMini',
					},
					EDIT_DISCOUNT_CODES: {
						target: 'updateDiscountCodes',
					},
				},
			},
			getCartMini: {
				invoke: {
					id: 'getCartMini',
					src: 'getMiniCart',
					onDone: {
						actions: 'updateProductQuantity',
						target: '#idle',
					},
					onError: {
						target: '#idle',
					},
				},
			},
			getCart: {
				tags: 'loadingCart',
				invoke: {
					id: 'getCart',
					src: 'getCart',
					onDone: {
						actions: 'updateData',
						target: 'idle',
					},
					onError: {
						target: 'idle',
					},
				},
			},
			addRemoveCart: {
				tags: 'loadingCart',
				invoke: {
					id: 'addRemoveCart',
					src: 'addRemoveCart',
					onDone: {
						actions: ['updateData', 'updateGTM'],
						target: 'idle',
					},
					onError: {
						target: 'idle',
					},
				},
			},
			updateDiscountCodes: {
				invoke: {
					id: 'updateDiscountCodes',
					src: 'updateDiscountCodes',
					onDone: {
						actions: ['updateData', 'updateGTM'],
						target: 'idle',
					},
					onError: {
						target: 'idle',
					},
				},
			},
			addOneFromBuyButton: {
				description: 'Add one product to cart',
				entry: [
					'setPurchaseButtonStateToLoading',
					'addOneFromBuyButtonEventData',
					'setGTMData',
				],
				invoke: {
					id: 'addOneFromBuyButton',
					src: 'addOneFromBuyButton',
					onDone: [
						{
							cond: 'productAddedSuccessfullyInCart',
							target: 'getCart',
							actions: [
								'setPurchaseButtonStateToSuccess',
								'updateProductQuantity',
								'sendEngagement',
								'updateGTM',
							],
						},
						{
							cond: 'hasAdditionalSales',
							target: 'idle',
							actions: [
								'setPurchaseButtonStateToSuccess',
								'updateProductQuantity',
								'setAdditionalSalesProducts',
								'updateGTM',
							],
						},
						{
							cond: 'productAddedSuccessfullyWithToast',
							target: 'idle',
							actions: [
								'setPurchaseButtonStateToSuccess',
								'updateProductQuantity',
								'showToastMessage',
								'updateGTM',
							],
						},
						{
							cond: 'productAddedSuccessfully',
							target: 'idle',
							actions: [
								'setPurchaseButtonStateToSuccess',
								'updateProductQuantity',
								'updateGTM',
							],
						},
						{
							target: 'idle',
							cond: 'shouldShowToastWhenFailed',
							actions: ['setPurchaseButtonStateToFailed', 'showToastMessage'],
						},
						{
							target: 'idle',
							actions: ['setPurchaseButtonStateToFailed'],
						},
					],
					onError: [
						{
							target: 'idle',
							cond: 'shouldShowToastWhenFailed',
							actions: ['setPurchaseButtonStateToFailed', 'showToastMessage'],
						},
						{
							target: 'idle',
							actions: ['setPurchaseButtonStateToFailed'],
						},
					],
				},
			},
			addMultipleVariantsMini: {
				entry: 'setPurchaseButtonStateToLoading',
				invoke: {
					id: 'addMultipleMini',
					src: 'addMultipleMini',
					onDone: [
						{
							cond: 'productAddedSuccessfully',
							actions: [
								'setPurchaseButtonStateToSuccess',
								'updateProductQuantity',
								'showToastMessage',
								'updateGTM',
							],
							target: 'idle',
						},
						{
							target: 'idle',
							actions: ['setPurchaseButtonStateToFailed', 'showToastMessage'],
						},
					],
					onError: {
						target: 'idle',
						actions: ['setPurchaseButtonStateToFailed', 'showToastMessage'],
					},
				},
			},
		},
	},
	{
		actions: {
			updateData: assign({
				cart: (context, event) => event.data,
				id: (context, event) => event.data?.id || '',
				totalQty: (context, event) => event.data.totalQty,
			}),
			updateProductQuantity: assign({
				totalQty: (_context, event) => event.data.totalQty,
			}),
			sendEngagement: () => {
				sendGlobalEvent('engagement', {
					type: 'addAdditionalSalesFromCart',
				});
			},
			showToastMessage: (context, event) => {
				let message = '';
				let success = false;
				if (
					is.object(event.data) &&
					'success' in event.data &&
					typeof event.data.success === 'boolean'
				) {
					success = event.data.success || false;
				}
				if (
					is.object(event.data) &&
					'message' in event.data &&
					typeof event.data.message === 'string'
				) {
					message = event.data.message || '';
				}

				sendToast(
					message,
					success ? 'success' : 'error',
					'/cart/',
					'continue_to_cart',
				);
			},
			addOneFromBuyButtonEventData: assign({
				showToast: (_context, event) => event.showToast,
				requestAdditionalSales: (_context, event) =>
					event.requestAdditionalSales,
			}),
			setAdditionalSalesProducts: assign({
				additionalSalesProducts: (_context, event) => event.data.accessories,
				additionalSalesToastText: (_context, event) => event.data.message || '',
				additionalSalesIsOpen: (_context) => true,
			}),
			clearAdditionalSalesProducts: assign({
				additionalSalesProducts: (_context) => undefined,
				additionalSalesToastText: (_context) => '',
				additionalSalesIsOpen: (_context) => false,
			}),
			setPurchaseButtonStateToSuccess: assign({
				purchaseButtonState: (context) => ({
					state: 'success' as const,
					buttonId: context.purchaseButtonState.buttonId,
				}),
			}),
			setPurchaseButtonStateToFailed: assign({
				purchaseButtonState: (context) => ({
					state: 'failure' as const,
					buttonId: context.purchaseButtonState.buttonId,
				}),
			}),
			setPurchaseButtonStateToLoading: assign({
				purchaseButtonState: (_context, event) => {
					if (event.type === 'ADD_ONE_FROM_BUY_BUTTON') {
						return {
							state: 'loading' as const,
							buttonId: event.buttonId || event.variantId || undefined,
						};
					}
					if (event.type === 'ADD_MULTIPLE_VARIANTS_MINI') {
						return {
							state: 'loading' as const,
							buttonId: event.buttonId || undefined,
						};
					}
					return {
						state: 'loading' as const,
						buttonId: undefined,
					};
				},
			}),
			updateGTM: (context, event) => {
				let cartId = '';
				if (event.type === 'done.invoke.addOneFromBuyButton') {
					cartId = event.data.id || '';
				}
				if (context.GTMData) {
					const { type } = context.GTMData;
					if (type === 'add_multiple_to_cart') {
						pushToGTM({
							type: 'add_multiple_to_cart',
							payload: { ...context.GTMData.payload, cartId },
						});
					}
					if (type === 'add_to_cart' || type === 'remove_from_cart') {
						pushToGTM({
							type,
							payload: { ...context.GTMData.payload, cartId },
						});
					}
				}
				return undefined;
			},
			setGTMData: assign({
				GTMData: (_context, event) => event.GTMData,
			}),
		},
		guards: {
			hasAdditionalSales: (_context, event) =>
				Boolean(event.data.accessories && event.data.accessories.length > 0),
			isInCart: () => {
				if (typeof window === 'undefined') return false;
				return pathStartsWith('cart');
			},
			productAddedSuccessfullyWithToast: (context, event) =>
				event.data.success && context.showToast,
			productAddedSuccessfullyInCart: (_context, event) =>
				event.data.success && pathStartsWith('cart'),
			productAddedSuccessfully: (_context, event) => event.data.success,
			shouldShowToastWhenFailed: (context) =>
				context.showToast || context.requestAdditionalSales,
		},
		services: {
			addOneFromBuyButton: (_context, event) =>
				requestCheckoutApi('addOneFromBuyButton', {
					variantId: event.variantId,
					requestAdditionalSales:
						'requestAdditionalSales' in event
							? event.requestAdditionalSales
							: false,
				}),
			getMiniCart: () => requestCheckoutApi('fetchMiniCart'),
			getCart: () => requestCheckoutApi('fetchCart'),
			addMultipleMini: (_context, event) => addMultipleToCart(event.variants),
			addRemoveCart: (_context, event) =>
				requestCheckoutApi('addRemoveCart', event),
			updateDiscountCodes: (context, event) => {
				let discountCodes: string[] = [];

				if (event.discountCode && event.variant === 'add') {
					discountCodes = [
						...getDiscountCodesDuplicate(context.cart),
						event.discountCode,
					];
				} else if (event.discountCode && event.variant === 'remove') {
					discountCodes = getDiscountCodesDuplicate(context.cart).filter(
						(code) => code !== event.discountCode,
					);
				}

				return requestCheckoutApi('updateCart', {
					cart: {
						...context.cart,
						discountCodes,
					} as ApiJulaModelsCartCartResponseModel,
					getCodes: false,
				});
			},
		},
	},
);

// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import account from './IconAccount';
import arrow from './IconArrow';
import bankIdNo from './IconBankIdNo';
import bankIdSe from './IconBankIdSe';
import bike from './IconBike';
import bonusChart from './IconBonusChart';
import bonusCoupon from './IconBonusCoupon';
import car from './IconCar';
import cartAdd from './IconCartAdd';
import cartDisabled from './IconCartDisabled';
import cart from './IconCart';
import chat from './IconChat';
import check from './IconCheck';
import clear from './IconClear';
import closeOutline from './IconCloseOutline';
import close from './IconClose';
import compare from './IconCompare';
import copyLink from './IconCopyLink';
import dashboard from './IconDashboard';
import description from './IconDescription';
import edit from './IconEdit';
import email from './IconEmail';
import error from './IconError';
import expandCollapse from './IconExpandCollapse';
import expand from './IconExpand';
import facebook from './IconFacebook';
import file from './IconFile';
import filterActive from './IconFilterActive';
import filter from './IconFilter';
import fire from './IconFire';
import help from './IconHelp';
import homeRepairService from './IconHomeRepairService';
import info from './IconInfo';
import instagram from './IconInstagram';
import julaJ from './IconJulaJ';
import localShipping from './IconLocalShipping';
import location from './IconLocation';
import menu from './IconMenu';
import minus from './IconMinus';
import offer from './IconOffer';
import openNewWindow from './IconOpenNewWindow';
import parcel from './IconParcel';
import pause from './IconPause';
import pinterest from './IconPinterest';
import play from './IconPlay';
import plus from './IconPlus';
import printer from './IconPrinter';
import reference from './IconReference';
import search from './IconSearch';
import settings from './IconSettings';
import share from './IconShare';
import shoppinglistAdd from './IconShoppinglistAdd';
import shoppinglist from './IconShoppinglist';
import spinner from './IconSpinner';
import starHalf from './IconStarHalf';
import starHollow from './IconStarHollow';
import star from './IconStar';
import store from './IconStore';
import support from './IconSupport';
import thumbDown from './IconThumbDown';
import thumbUp from './IconThumbUp';
import users from './IconUsers';
import verifiedUser from './IconVerifiedUser';
import visibilityOff from './IconVisibilityOff';
import visibility from './IconVisibility';
import watchLater from './IconWatchLater';
import youtube from './IconYoutube';

export const icons = {
	account,
	arrow,
	bankIdNo,
	bankIdSe,
	bike,
	bonusChart,
	bonusCoupon,
	car,
	cartAdd,
	cartDisabled,
	cart,
	chat,
	check,
	clear,
	closeOutline,
	close,
	compare,
	copyLink,
	dashboard,
	description,
	edit,
	email,
	error,
	expandCollapse,
	expand,
	facebook,
	file,
	filterActive,
	filter,
	fire,
	help,
	homeRepairService,
	info,
	instagram,
	julaJ,
	localShipping,
	location,
	menu,
	minus,
	offer,
	openNewWindow,
	parcel,
	pause,
	pinterest,
	play,
	plus,
	printer,
	reference,
	search,
	settings,
	share,
	shoppinglistAdd,
	shoppinglist,
	spinner,
	starHalf,
	starHollow,
	star,
	store,
	support,
	thumbDown,
	thumbUp,
	users,
	verifiedUser,
	visibilityOff,
	visibility,
	watchLater,
	youtube,
};

// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconInfo(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M12 22A10.011 10.011 0 0 1 2 12 10.012 10.012 0 0 1 12 2a10.012 10.012 0 0 1 10 10 10.011 10.011 0 0 1-10 10zm-1.751-10.747a1.007 1.007 0 0 1 .685.168.829.829 0 0 1 .182.608 2.857 2.857 0 0 1-.058.537 10.2 10.2 0 0 1-.144.616l-.644 2.277a6.4 6.4 0 0 0-.124.641 4.391 4.391 0 0 0-.037.558 1.47 1.47 0 0 0 .516 1.157 2.126 2.126 0 0 0 1.455.458 3.2 3.2 0 0 0 1.066-.161c.33-.115.744-.271 1.232-.463l.175-.7a2.986 2.986 0 0 1-.43.144 2.025 2.025 0 0 1-.529.082 1.122 1.122 0 0 1-.7-.164.765.765 0 0 1-.2-.616 3.279 3.279 0 0 1 .062-.529c.049-.253.1-.457.145-.624l.641-2.269a3.744 3.744 0 0 0 .128-.687c.019-.23.034-.415.034-.521a1.493 1.493 0 0 0-.506-1.165 2.083 2.083 0 0 0-1.428-.45 3.554 3.554 0 0 0-1.093.186c-.41.132-.817.28-1.21.442l-.169.7c.142-.051.292-.1.445-.148a1.867 1.867 0 0 1 .505-.077zM13.181 6a1.56 1.56 0 0 0-1.082.417 1.354 1.354 0 0 0-.451 1.013 1.339 1.339 0 0 0 .451 1.013 1.557 1.557 0 0 0 1.082.418 1.56 1.56 0 0 0 1.084-.418 1.336 1.336 0 0 0 .446-1.013 1.351 1.351 0 0 0-.446-1.013A1.564 1.564 0 0 0 13.181 6z" />
		</svg>
	);
}
IconInfo.displayName = 'IconInfo';

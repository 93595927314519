// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconOffer(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="m21.41 11.58-9-9A1.99 1.99 0 0 0 11 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 .59 1.42l9 9A1.99 1.99 0 0 0 13 22a1.96 1.96 0 0 0 1.41-.59l7-7A1.96 1.96 0 0 0 22 13a2.02 2.02 0 0 0-.59-1.42zM5.5 7A1.5 1.5 0 1 1 7 5.5 1.5 1.5 0 0 1 5.5 7z" />
		</svg>
	);
}
IconOffer.displayName = 'IconOffer';

// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconOpenNewWindow(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M20.23 3.77a.87.87 0 0 0-.64-.27h-5.8a.86.86 0 0 0-.66.27.89.89 0 0 0-.26.64.9.9 0 0 0 .91.9h3.66l-8.02 8a.83.83 0 0 0-.26.63.88.88 0 0 0 .26.61.93.93 0 0 0 .65.29.8.8 0 0 0 .62-.24l8-8.01v3.63a.88.88 0 0 0 .26.64.85.85 0 0 0 .62.27.92.92 0 0 0 .66-.27.86.86 0 0 0 .27-.64V4.4a.87.87 0 0 0-.27-.64" />
			<path d="M19.6 18.7H5.3V4.4a.9.9 0 0 0-.9-.9.86.86 0 0 0-.64.26.89.89 0 0 0-.26.65v14.3a1.73 1.73 0 0 0 .54 1.26 1.73 1.73 0 0 0 1.26.53h14.3a.9.9 0 0 0 .64-1.53.88.88 0 0 0-.64-.26" />
		</svg>
	);
}
IconOpenNewWindow.displayName = 'IconOpenNewWindow';

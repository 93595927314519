// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconDescription(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M14.59 2.59A2 2 0 0 0 13.17 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 1.99 2H18a2 2 0 0 0 2-2V8.83c0-.53-.21-1.04-.59-1.41l-4.82-4.83ZM15 18H9a1 1 0 0 1-1-1 1 1 0 0 1 1-1h6a1 1 0 0 1 1 1 1 1 0 0 1-1 1Zm0-4H9a1 1 0 0 1-1-1 1 1 0 0 1 1-1h6a1 1 0 0 1 1 1 1 1 0 0 1-1 1Zm-2-6V3.5L18.5 9H14a1 1 0 0 1-1-1Z" />
		</svg>
	);
}
IconDescription.displayName = 'IconDescription';

import React from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import type { AppProps } from 'next/app';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { SWRConfig } from 'swr';
import { interpret } from 'xstate';

import DevModeBanner from 'components/DevModeBanner';
import {
	FeatureToggleContextProvider,
	GlobalStateContextProvider,
	SelectedStoreContextProvider,
	SelectedStoreInfoContextProvider,
} from 'contexts';
import { useEffectOnce, useGlobalEvent } from 'hooks';
import { globalFetchLockMachine } from 'state-machines/globalFetchLock.machine';
import { i18n, I18nProvider } from 'utils/i18n';

import '../styles/globals.css';
import '@adyen/adyen-web/dist/adyen.css';
import 'components/AdyenPayment/AdyenPayment.css';
import 'nprogress/nprogress.css';

if (
	process.env.NODE_ENV === 'development' &&
	process.env.NEXT_PUBLIC_API_MOCKING === 'enabled'
) {
	// eslint-disable-next-line global-require
	require('../mocks');
}

NProgress.configure({ showSpinner: false, trickleSpeed: 100 });
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const globalFetchLockService = interpret(globalFetchLockMachine, {
	devTools: true,
});
if (typeof window !== 'undefined') {
	window.globalFetchLockService = globalFetchLockService.start();
}

type Props = AppProps<{
	dictionary: any;
	layoutData: any;
	locale: any;
}>;

function App({ Component, pageProps }: Props): JSX.Element {
	const router = useRouter();
	const { dictionary, ...componentProps } = pageProps;

	useEffectOnce(() => {
		if (process.env.NEXT_PUBLIC_APPINSIGHTS_CONNECTIONSTRING) {
			const appInsights = new ApplicationInsights({
				config: {
					connectionString:
						process.env.NEXT_PUBLIC_APPINSIGHTS_CONNECTIONSTRING,
				},
			});
			appInsights.loadAppInsights();
			appInsights.trackPageView();
		}
	});

	// TODO: is this needed? Appears to be unused.
	useGlobalEvent('reload', () => {
		router.push(window.location.href);
	});

	return (
		<SelectedStoreContextProvider>
			<SelectedStoreInfoContextProvider>
				<FeatureToggleContextProvider
					featureToggles={pageProps.layoutData.sitecore?.context.featureToggles}
				>
					{/* Use the next-localization (w/ rosetta) library to provide our
							translation dictionary to the app. Note that Next.js does not
							currently provide anything for translation, only i18n routing. */}
					<GlobalStateContextProvider
						sitecoreContext={pageProps.layoutData.sitecore?.context}
					>
						<SWRConfig value={{ loadingTimeout: 10_000 }}>
							<I18nProvider
								lngDict={dictionary}
								locale={pageProps.locale}
								i18nInstance={i18n}
							>
								<>
									<DevModeBanner />
									<Component {...componentProps} />
								</>
							</I18nProvider>
						</SWRConfig>
					</GlobalStateContextProvider>
				</FeatureToggleContextProvider>
			</SelectedStoreInfoContextProvider>
		</SelectedStoreContextProvider>
	);
}
App.displayName = 'App';

export default function AppWrapper(props: Props): JSX.Element | null {
	if (!props.pageProps) {
		console.error(
			'Missing pageProps from Sitecore in App root, aborting render',
		);
		return null;
	}
	if (!props.pageProps.layoutData) {
		console.error(
			'Missing pageProps.layoutData from Sitecore in App root, aborting render',
		);
		return null;
	}
	return <App {...props} />;
}
AppWrapper.displayName = 'AppWrapper';

import { ApiJulaModelsCartMinimizedAddToCartResponseModel } from 'models/api';
import fetchData, { API_URL, ExtendedResponse } from 'utils/fetchData';

export async function addMultipleToCart(
	variants: { variantId: string; quantity: number }[],
) {
	const response = await fetchData<
		ExtendedResponse<ApiJulaModelsCartMinimizedAddToCartResponseModel>
	>(
		`${API_URL}Cart/mini/variants`,
		{ method: 'POST', body: JSON.stringify(variants) },
		true,
	);
	if (!response || response?.status < 200 || response?.status >= 300) {
		throw response;
	}
	return response.responseData;
}

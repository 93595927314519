// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconFilter(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<rect width="16" height="2" rx="1" transform="translate(4 7)" />
			<rect width="16" height="2" rx="1" transform="translate(4 15)" />
			<rect width="2" height="6" rx="1" transform="translate(7 5)" />
			<rect width="2" height="6" rx="1" transform="translate(15 13)" />
		</svg>
	);
}
IconFilter.displayName = 'IconFilter';

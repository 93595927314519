import type { ActorRef, State } from 'xstate';
import { assign, createMachine } from 'xstate';

type MenuMachineContext = {
	isOpen: boolean;
};

type OpenMenuEvent = { type: 'OPEN_MENU' };
type CloseMenuEvent = { type: 'CLOSE_MENU' };

type MenuMachineEvents = OpenMenuEvent | CloseMenuEvent;

export type MenuMachineState = State<MenuMachineContext, MenuMachineEvents>;
export type MenuMachineActor = ActorRef<MenuMachineEvents, MenuMachineState>;

export const selectIsOpening = (state: MenuMachineState) =>
	state.matches('opening');

export const selectIsClosing = (state: MenuMachineState) =>
	state.matches('closing');

export const selectIsOpen = (state: MenuMachineState) => state.context.isOpen;

export const menuMachine = createMachine<MenuMachineContext, MenuMachineEvents>(
	{
		id: 'menu',
		initial: 'idle',
		context: {
			isOpen: false,
		},
		states: {
			idle: {
				on: {
					OPEN_MENU: { target: 'opening' },
					CLOSE_MENU: { target: 'closing' },
				},
			},
			opening: {
				entry: [assign((context) => ({ isOpen: true }))],
				always: 'idle',
			},
			closing: {
				entry: [assign((context) => ({ isOpen: false }))],
				always: 'idle',
			},
		},
	},
);

import { publicRuntimeConfig } from 'config';
import type {
	ApiJulaModelsCartCartResponseModel,
	ApiJulaModelsCartJulaVariantModel,
	ApiJulaModelsCartSummaryModel,
	WishlistVariant,
} from 'models/api';
import type { Product } from 'models/product';
import type {
	AccessoryCard,
	ProductCard as ProductCardModel,
} from 'models/productCard';
import { assertUnreachable } from 'utils/helpers';

import type {
	AddRemoveCartItemGTMProduct,
	GTMEvent,
	ItemWithPrice,
	ListItemGTMProduct,
	Purchase,
	SearchListItemGTMProduct,
	ViewItemGTMProduct,
	WishListItemGTMProduct,
} from './GTMEvents';
import type {
	AddRemoveFromCartGTMHeplerEventProductData,
	GTMHelperInput,
	WishListAddProduct,
} from './GTMHelperInputs';

function getGTMViewItemProductData(product: Product): ViewItemGTMProduct {
	return {
		price: product.displayPrice?.priceIncVat?.value ?? 0,
		quantity: 1,
		item_id: product.id,
	};
}

function getGTMListItemProductData(
	product: ProductCardModel,
	index: number,
	listName: string,
): ListItemGTMProduct {
	return {
		index,
		item_id: product.id,
		item_list_name: listName,
	};
}

function getGTMSearchListItemProductData(
	product: ProductCardModel,
	index: number,
	listName: string,
	itemListId: string,
): SearchListItemGTMProduct {
	return {
		index,
		item_id: product.id,
		item_list_name: listName,
		item_list_id: itemListId,
	};
}

function getPrice(
	product:
		| Product
		| ProductCardModel
		| ApiJulaModelsCartJulaVariantModel
		| WishlistVariant
		| AccessoryCard,
): number {
	let price = 0;
	if ('listPrice' in product) {
		price = product.listPrice?.priceIncVat?.value ?? 0;
	}
	if ('displayPrice' in product) {
		price = product.displayPrice?.priceIncVat?.value ?? 0;
	}
	if ('price' in product) {
		price = product.price.priceIncVat?.value ?? 0;
	}
	return price;
}

function getGTMWishListTotalValue(
	product: WishListAddProduct,
	quantity: number,
): number {
	return getPrice(product) * quantity;
}

function getGTMWishListItemProductData(
	product: WishListAddProduct,
	quantity: number,
): WishListItemGTMProduct {
	return {
		item_id: product.id!,
		price: getPrice(product),
		quantity,
	};
}

function getCurrency() {
	switch (publicRuntimeConfig?.NEXT_PUBLIC_JULA_MARKET_CODE) {
		case 'SE':
			return 'SEK';

		case 'NO':
			return 'NOK';

		case 'PL':
			return 'PLN';

		case 'FI':
		case 'AT':
		case 'DE':
			return 'EUR';

		default:
			return 'unknown market';
	}
}

function getGTMAddRemoveItemProductData({
	product,
	quantity,
}: AddRemoveFromCartGTMHeplerEventProductData): AddRemoveCartItemGTMProduct {
	return {
		item_id: product.id!,
		price: getPrice(product),
		quantity,
		currency: getCurrency(),
	};
}

function getGTMAddRemoveItemsProductData(
	productsPayload: AddRemoveFromCartGTMHeplerEventProductData[],
): AddRemoveCartItemGTMProduct[] {
	return productsPayload.map(getGTMAddRemoveItemProductData);
}

function getGTMItemsWithIdPriceQty(
	products: ApiJulaModelsCartJulaVariantModel[],
): ItemWithPrice[] {
	return products.map((product) => ({
		item_id: product.id,
		price: product.price.priceIncVat?.value ?? 0,
		quantity: product.qty,
	}));
}

function getGTMProductDataList(
	productList: ProductCardModel[],
	listName: string,
	pageSize: number,
	itemListId?: string,
): SearchListItemGTMProduct[] | ListItemGTMProduct[] {
	let pages = Math.trunc(productList.length / pageSize);

	pages += productList.length % pageSize > 0 ? 1 : 0;

	const startIndex = pages > 1 ? (pages - 1) * pageSize : 0;

	if (itemListId) {
		const searchListItemGTMProduct: SearchListItemGTMProduct[] = [];
		for (let i = startIndex; i < productList.length; i++) {
			searchListItemGTMProduct.push(
				getGTMSearchListItemProductData(
					productList[i]!,
					i + 1,
					listName,
					itemListId,
				),
			);
		}
		return searchListItemGTMProduct;
	}
	const gtmProductDataList: ListItemGTMProduct[] = [];
	for (let i = startIndex; i < productList.length; i++) {
		gtmProductDataList.push(
			getGTMListItemProductData(productList[i]!, i + 1, listName),
		);
	}
	return gtmProductDataList;
}

function getSumValue(
	cart: ApiJulaModelsCartCartResponseModel,
	sumType: ApiJulaModelsCartSummaryModel['sumType'],
): number {
	const summary = cart.summaries.find((sum) => sum.sumType === sumType);
	return summary?.value ?? 0;
}

function getGTMPurchaseData(
	cart: ApiJulaModelsCartCartResponseModel,
): Purchase {
	return {
		transaction_id: cart.orderNumber!,
		cart_id: cart.id,
		value: getSumValue(cart, 'Total'),
		tax: getSumValue(cart, 'Vat'),
		shipping: getSumValue(cart, 'Freight'),
		currency: getCurrency(),
		items: cart.variants.map((variant) => ({
			item_id: variant.id,
			price: variant.price.priceIncVat?.value ?? 0,
			quantity: variant.qty,
		})),
	};
}

// Want explicit case handling with an unreachable default.
// eslint-disable-next-line consistent-return
export function formatGTMData(event: GTMHelperInput): GTMEvent {
	const { type } = event;
	switch (type) {
		case 'view_item_list':
			return {
				event: type,
				ecommerce: {
					items: [
						...getGTMProductDataList(
							event.payload.products,
							event.payload.listName,
							event.payload.pageSize,
						),
					],
				},
			};

		case 'site_search':
			return {
				event: 'view_item_list',
				ecommerce: {
					items: [
						...getGTMProductDataList(
							event.payload.products,
							event.payload.listName,
							event.payload.pageSize,
							event.payload.itemListId,
						),
					],
				},
			};

		case 'select_item':
			return {
				event: type,
				ecommerce: {
					items: [
						getGTMListItemProductData(
							event.payload.selectedProduct,
							event.payload.productList.indexOf(event.payload.selectedProduct) +
								1,
							event.payload.listName,
						),
					],
				},
			};

		case 'search_list_select_item':
			return {
				event: 'select_item',
				ecommerce: {
					items: [
						getGTMSearchListItemProductData(
							event.payload.selectedProduct,
							event.payload.productList.indexOf(event.payload.selectedProduct) +
								1,
							event.payload.listName,
							event.payload.itemListId,
						),
					],
				},
			};

		case 'view_item':
			return {
				event: type,
				ecommerce: {
					currency: getCurrency(),
					items: [getGTMViewItemProductData(event.payload.product)],
					category:
						event.payload.product.category1Name || 'CategoryName missing',
				},
			};

		case 'purchase':
			return {
				event: type,
				ecommerce: getGTMPurchaseData(event.payload.cart),
			};

		case 'add_to_cart':
		case 'remove_from_cart':
			return {
				event: type,
				ecommerce: {
					items: [getGTMAddRemoveItemProductData(event.payload.productData)],
					cart_id: event.payload.cartId,
				},
			};

		case 'add_multiple_to_cart':
			return {
				event: 'add_to_cart',
				ecommerce: {
					items: [
						...getGTMAddRemoveItemsProductData(event.payload.productsData),
					],
					cart_id: event.payload.cartId,
				},
			};

		case 'add_to_wishlist':
			return {
				event: type,
				ecommerce: {
					value: getGTMWishListTotalValue(
						event.payload.product,
						event.payload.quantity,
					),
					currency: getCurrency(),
					items: [
						getGTMWishListItemProductData(
							event.payload.product,
							event.payload.quantity,
						),
					],
				},
			};

		case 'view_cart':
			return {
				event: type,
				ecommerce: {
					items: [...getGTMItemsWithIdPriceQty(event.payload.products)],
					cart_id: event.payload.cartId,
				},
			};

		case 'begin_checkout':
			return {
				event: type,
				ecommerce: {
					items: [...getGTMItemsWithIdPriceQty(event.payload.products)],
				},
			};

		case 'add_payment_info':
			return {
				event: type,
				ecommerce: {
					payment_type: event.payload.paymentType,
					items: [...getGTMItemsWithIdPriceQty(event.payload.products)],
				},
			};

		case 'add_shipping_info':
			return {
				event: type,
				ecommerce: {
					shipping_tier: event.payload.shippingTier,
					items: [...getGTMItemsWithIdPriceQty(event.payload.products)],
				},
			};

		case 'login':
		case 'login_renewal':
			return {
				event: type,
				voyado_id: event.payload.voyadoId,
			};

		case 'order_history_end':
			return {
				event: type,
				date: event.payload.date,
			};

		case 'open_credit_simulation':
		case 'logout':
		case 'search_term_brand_view':
		case 'search_term_category_view':
		case 'search_term_brand_select':
		case 'search_term_category_select':
		case 'select_store_open':
			return {
				event: type,
			};

		case 'select_store_choice':
			return {
				event: type,
				payload: {
					store_name: event.payload.storeName,
					store_stock: event.payload.storeStock,
				},
			};

		case 'view_promotion':
		case 'select_promotion':
			return {
				event: type,
				promotion_id: event.payload.id,
				promotion_name: event.payload.name,
			};

		default:
			assertUnreachable(type, 'GTMEvent.type');
	}
}

// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconAccount(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<circle cx="5" cy="5" r="5" transform="translate(7 1)" />
			<path d="M20.1 22a.96.96 0 0 0 .9-1.12 9.07 9.07 0 0 0-17.99 0A.96.96 0 0 0 3.9 22z" />
		</svg>
	);
}
IconAccount.displayName = 'IconAccount';

import { assign, createMachine, sendParent } from 'xstate';
import { pure } from 'xstate/lib/actions';

import { ApiJulaModelsCartCartDeliveryAddress } from 'models/api';
import { Customer } from 'models/customer';

import { requestCustomerApi } from './userInfo.services';
import type {
	UserInfoMachineContext,
	UserInfoMachineEvents,
} from './userInfo.types';

export const userInfoMachine = createMachine(
	{
		id: 'userInfo',
		initial: 'idle',
		schema: {
			context: {} as UserInfoMachineContext,
			events: {} as UserInfoMachineEvents,
			services: {} as {
				searchCustomer: { data: Customer };
			},
		},
		tsTypes: {} as import('./userInfo.machine.typegen').Typegen0,
		context: {
			userInfoErrorTypes: [],
		},
		states: {
			idle: {
				id: 'idle',
				on: {
					CART_UPDATED: {
						target: 'idle',
						actions: 'updateData',
					},
					UPDATE_USER_INFO_USE_ALT_ADDRESS: {
						target: 'loading',
						actions: ['setUseAlternativeAddress', 'saveUseAlternativeAddress'],
					},
					UPDATE_USER_INFO_FROM_STEP_TWO_SIGN_IN: {
						target: 'checkIsCustomerAndUpdateCart',
						actions: 'saveEmailAndPostalCodeToContext',
					},
				},
			},
			checkIsCustomerAndUpdateCart: {
				entry: 'saveEmailAndPostalCode',
				initial: 'loading',
				states: {
					loading: {
						on: {
							CART_UPDATED: {
								target: 'checkIsCustomer',
								actions: 'updateData',
							},
						},
					},
					checkIsCustomer: {
						invoke: {
							id: 'searchCustomer',
							src: 'searchCustomer',
							onDone: {
								target: '#idle',
								actions: 'setCustomerInfo',
							},
							onError: {
								target: '#idle',
							},
						},
					},
				},
			},
			debounceFullUserInfoSave: {
				after: {
					250: { target: 'loading', actions: ['updateCart'] },
				},
			},
			debounceTelephoneNumberSave: {
				after: {
					250: { target: 'loading', actions: ['savePhone'] },
				},
			},
			loading: {
				on: {
					CART_UPDATED: {
						target: 'idle',
						actions: 'updateData',
					},
				},
			},
		},
		on: {
			UPDATE_ANONYMOUS_USER_CUSTOMER_INFO: {
				target: '.debounceFullUserInfoSave',
				internal: false,
				actions: ['saveAnonymousUserCustomerInfoToContext'],
			},
			UPDATE_ANONYMOUS_USER_ALTERNATIVE_ADDRESS: {
				target: '.debounceFullUserInfoSave',
				internal: false,
				actions: ['saveAnonymousUserAltAddressToContext'],
			},
			UPDATE_PRO_USER_ALTERNATIVE_ADDRESS: {
				target: '.debounceFullUserInfoSave',
				internal: false,
				actions: ['saveProUserAltAddressToContext'],
			},
			UPDATE_CLUB_USER_ALTERNATIVE_ADDRESS: {
				target: '.debounceFullUserInfoSave',
				internal: false,
				actions: ['saveClubUserAltAddressToContext'],
			},
			UPDATE_CLUB_USER_UNREGISTERED_COMPANY_INFO: {
				target: '.debounceFullUserInfoSave',
				internal: false,
				actions: ['saveClubUserUnregisteredCompanyInfoToContext'],
			},
			UPDATE_USER_INFO_PHONE: {
				target: '.debounceTelephoneNumberSave',
				internal: false,
				actions: ['saveTelephoneToContext'],
			},
			RESET_USER_INFO_FROM_STEP_TWO_SIGN_IN: {
				actions: ['resetCustomerInfo'],
			},
		},
	},
	{
		actions: {
			updateData: assign({
				email: (context, event) => event?.value?.email || context.email,
				telephoneNumber: (context, event) =>
					event?.value?.telephoneNumber || context.telephoneNumber,
				customerAddress: (context, event) =>
					event?.value?.customerAddress || context.customerAddress,
				deliveryAddress: (context, event) =>
					event?.value?.deliveryAddress || context.deliveryAddress,
				isAlternativeDeliveryAddressSelectable: (_context, event) =>
					event?.value?.isAlternativeDeliveryAddressSelectable,
				alternativeDeliveryAddress: (_context, event) =>
					event?.value?.alternativeDeliveryAddress,
				unregisteredCompanyInfo: (_context, event) =>
					event?.value?.unregisteredCompanyInfo,
				companyIdentificationNumber: (_context, event) =>
					event?.value?.companyIdentificationNumber,
				error: (context, event) => event?.value?.errorList || context.error,
			}),
			saveAnonymousUserCustomerInfoToContext: assign({
				email: (context, event) => event?.email,
				customerAddress: (context, event) => event?.customerAddress,
				telephoneNumber: (context, event) => event?.telephoneNumber,
				alternativeDeliveryAddress: (_, event) =>
					event.useAlternativeDeliveryAddress,
				unregisteredCompanyInfo: (_, event) => event?.unregisteredCompanyInfo,
			}),
			saveAnonymousUserAltAddressToContext: assign({
				deliveryAddress: (context, event) => {
					const updatedDeliveryAddress: ApiJulaModelsCartCartDeliveryAddress = {
						firstName: event?.deliveryAddress?.firstName,
						lastName: event?.deliveryAddress?.lastName,
						address: event?.deliveryAddress?.address,
						coAddress: event?.deliveryAddress?.coAddress,
						postalCode: event?.deliveryAddress?.postalCode,
						city: event?.deliveryAddress?.city,
					};
					return updatedDeliveryAddress;
				},
			}),
			saveProUserAltAddressToContext: assign({
				deliveryAddress: (context, event) => {
					const updatedDeliveryAddress: ApiJulaModelsCartCartDeliveryAddress = {
						firstName: context?.customerAddress?.firstName,
						lastName: context?.customerAddress?.lastName,
						address: event?.deliveryAddress?.address,
						coAddress: event?.deliveryAddress?.coAddress,
						postalCode: event?.deliveryAddress?.postalCode,
						city: event?.deliveryAddress?.city,
						companyName: context?.customerAddress?.companyName,
					};
					return updatedDeliveryAddress;
				},
			}),
			saveClubUserAltAddressToContext: assign({
				deliveryAddress: (context, event) => {
					const updatedDeliveryAddress: ApiJulaModelsCartCartDeliveryAddress = {
						firstName: event?.deliveryAddress?.firstName,
						lastName: event?.deliveryAddress?.lastName,
						address: event?.deliveryAddress?.address,
						coAddress: event?.deliveryAddress?.coAddress,
						postalCode: event?.deliveryAddress?.postalCode,
						city: event?.deliveryAddress?.city,
					};
					return updatedDeliveryAddress;
				},
			}),
			saveClubUserUnregisteredCompanyInfoToContext: assign({
				unregisteredCompanyInfo: (_, event) => event?.unregisteredCompanyInfo,
			}),
			saveTelephoneToContext: assign({
				telephoneNumber: (_context, event) => event?.value?.telephoneNumber,
			}),
			saveEmailAndPostalCodeToContext: assign({
				email: (_context, event) => event?.email,
				customerAddress: (context, event) => ({
					...context?.customerAddress,
					postalCode: event?.postalCode,
				}),
			}),
			setUseAlternativeAddress: assign({
				alternativeDeliveryAddress: (context, event) =>
					event?.value?.useAlternativeDeliveryAddress,
			}),
			updateCart: pure((context, _event) =>
				sendParent({
					type: 'UPDATE_CART',
					email: context.email,
					telephoneNumber: context.telephoneNumber,
					customerAddress: context?.customerAddress,
					deliveryAddress: context?.deliveryAddress,
					alternativeDeliveryAddress: context?.alternativeDeliveryAddress,
					unregisteredCompanyInfo: context?.unregisteredCompanyInfo,
				}),
			),
			savePhone: pure((context, _event) =>
				sendParent({
					type: 'UPDATE_CART',
					telephoneNumber: context.telephoneNumber,
				}),
			),
			saveUseAlternativeAddress: pure((_context, event) =>
				sendParent({
					type: 'UPDATE_CART',
					alternativeDeliveryAddress: event.value.useAlternativeDeliveryAddress,
				}),
			),
			saveEmailAndPostalCode: pure((context, event) =>
				sendParent({
					type: 'UPDATE_CART',
					email: context.email,
					customerAddress: context.customerAddress,
				}),
			),
			setCustomerInfo: assign({
				customer: (_, event) => event?.data,
			}),
			resetCustomerInfo: assign({
				customer: (_context, _event) => undefined,
			}),
		},
		services: {
			searchCustomer: async (context, event) =>
				requestCustomerApi(context.email),
		},
	},
);

import { useSelector } from '@xstate/react';
import { waitFor } from 'xstate/lib/waitFor';

import { useCheckoutContext } from 'contexts';
import { ApiJulaModelsCartCartErrorModelTypes, CartFieldKey } from 'models/api';
import { selectUserInfoActor } from 'state-machines/checkout';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Field {
	errorKeys?: ApiJulaModelsCartCartErrorModelTypes[];
	name: CartFieldKey;
	value: unknown;
}

export type ValidateField = ReturnType<typeof useValidateCheckoutField>;

export function useValidateCheckoutField(requiredFields?: string[]) {
	const { checkoutService } = useCheckoutContext();
	const userInfoActor = useSelector(checkoutService, selectUserInfoActor);

	const { t } = useI18n();
	return async ({ name, errorKeys, value }: Field) => {
		const relatedErrorKeys: string[] = errorKeys ?? [name];
		relatedErrorKeys.includes(name);
		const data = await waitFor(userInfoActor, (state) => state.matches('idle'));
		const errors = [
			...(data.context?.error
				?.filter(
					(error) =>
						// for now all fields don't have their name as key in the error list so we need to check for type as well
						(error.key && relatedErrorKeys.includes(error.key)) ||
						(error.type && relatedErrorKeys.includes(error?.type)),
				)
				.map((error) => error.description) ?? []),
		];
		if (requiredFields?.includes(name) && !value) {
			errors.push(t('FieldIsRequired'));
		}

		if (is.arrayWithLength(errors)) {
			return errors;
		}
		return undefined;
	};
}

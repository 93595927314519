// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconHomeRepairService(
	props: SVGAttributes<SVGElement>,
) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M17 16a1 1 0 0 1-1-1H8a1 1 0 0 1-1 1 1 1 0 0 1-1-1H2v3c0 1.1.9 2 2 2h16a2 2 0 0 0 2-2v-3h-4a1 1 0 0 1-1 1Z" />
			<path d="M20 8h-3V6a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H4a2 2 0 0 0-2 2v4h4v-1a1 1 0 0 1 1-1 1 1 0 0 1 1 1v1h8v-1a1 1 0 0 1 1-1 1 1 0 0 1 1 1v1h4v-4a2 2 0 0 0-2-2Zm-5 0H9V6h6v2Z" />
		</svg>
	);
}
IconHomeRepairService.displayName = 'IconHomeRepairService';

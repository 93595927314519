/**
 * ConsentAndAnalytics
 */

import React, { useEffect } from 'react';
import { useSelector } from '@xstate/react';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { publicRuntimeConfig } from 'config';
import { useGlobalStateContext } from 'contexts';
import { useEngagementGoals, useGlobalEvent } from 'hooks';
import type { JulaSitecoreServiceData } from 'lib/page-props';
import { selectIsAuthenticatedUser } from 'state-machines/authentication';

interface Props {
	layoutData: JulaSitecoreServiceData;
	userInfo?: { loginMethod: string; userType: string };
}

/** Scripts for cookie consent and analytics. */
export default function ConsentAndAnalytics({ userInfo, layoutData }: Props) {
	const router = useRouter();
	const { userService } = useGlobalStateContext();
	const { sendEngagement } = useEngagementGoals(layoutData);

	const isAuthenticatedUser = useSelector(
		userService,
		selectIsAuthenticatedUser,
	);

	useGlobalEvent('engagement', (e) => {
		sendEngagement({ ...e.detail, loggedIn: isAuthenticatedUser });
	});

	useEffect(() => {
		const handler = () => {
			if (
				typeof window !== 'undefined' &&
				typeof window.OneTrust?.initializeCookiePolicyHtml === 'function'
			) {
				window.OneTrust?.initializeCookiePolicyHtml();
			}
		};
		router.events.on('routeChangeComplete', handler);
		return () => {
			router.events.off('routeChangeComplete', handler);
		};
	}, [router]);

	const marketSpecificGTMScript = publicRuntimeConfig?.NEXT_PUBLIC_GTM_JULA
		? "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
			"  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
			"  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
			"  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
			`})(window,document,'script','dataLayer','${publicRuntimeConfig.NEXT_PUBLIC_GTM_JULA}');`
		: '';

	const getGoogleTagManagerScript = () => {
		switch (publicRuntimeConfig?.ENVIRONMENT_TYPE) {
			case 'production':
				return (
					"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
					"    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
					"    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
					"    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
					`  })(window,document,'script','dataLayer','${publicRuntimeConfig?.NEXT_PUBLIC_GTM_CONTAINER_ID}');`
				);
			case 'stage':
				return (
					"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
					"  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
					"  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
					"  'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=WHHQB9dfYHFgMLamv6MtHw&gtm_preview=env-4&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);\n" +
					`})(window,document,'script','dataLayer','${publicRuntimeConfig?.NEXT_PUBLIC_GTM_CONTAINER_ID}';`
				);
			// test and others
			default:
				return (
					"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
					"  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
					"  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
					"  'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=1WVzxwLheUa57gU_iu6qlw&gtm_preview=env-3&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);\n" +
					`})(window,document,'script','dataLayer','${publicRuntimeConfig?.NEXT_PUBLIC_GTM_CONTAINER_ID}');`
				);
		}
	};

	function generateDefaultData(): string {
		let defaultData = `
    function gtag(){dataLayer.push(arguments);}

    gtag('consent', 'default', {
      'ad_storage': 'denied',
      'analytics_storage': 'denied',
      'ad_user_data': 'denied',
      'ad_personalization': 'denied'
    });

    dataLayer.push({
      'event': 'default_consent',
      'market': '${publicRuntimeConfig?.NEXT_PUBLIC_JULA_MARKET_CODE}'
    });
  `;

		if (userInfo) {
			defaultData += `
      dataLayer.push({
        'user_info':{
          'type':'${userInfo.userType}',
          'login_method':'${userInfo.loginMethod}'
        }
      });
    `;
		}

		return defaultData;
	}

	return (
		<>
			{publicRuntimeConfig?.NEXT_PUBLIC_FATHOM_CONTAINER_ID && (
				<Script
					id="fathom-script"
					src="https://cdn.usefathom.com/script.js"
					type="text/javascript"
					data-site={publicRuntimeConfig?.NEXT_PUBLIC_FATHOM_CONTAINER_ID}
					data-spa="history"
					strategy="afterInteractive"
				/>
			)}
			<Script
				id="gtm-datalayer"
				type="text/javascript"
				dangerouslySetInnerHTML={{
					__html: 'window.dataLayer = window.dataLayer || [];',
				}}
			/>
			<Script
				id="gtm-defaultData"
				type="text/plain"
				className="optanon-category-C0001-C0002-C0003"
				strategy="afterInteractive"
			>
				{generateDefaultData()}
			</Script>
			<Script
				id="gtm-script"
				strategy="afterInteractive"
				type="text/plain"
				className="optanon-category-C0001-C0002-C0003"
				dangerouslySetInnerHTML={{ __html: getGoogleTagManagerScript() }}
			/>
			{marketSpecificGTMScript && (
				<Script
					id="gtm-market-specific-script"
					strategy="afterInteractive"
					type="text/plain"
					className="optanon-category-C0001-C0002-C0003"
					dangerouslySetInnerHTML={{
						__html: marketSpecificGTMScript,
					}}
				/>
			)}
			<Script
				id="onetrust-cdn"
				src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
				type="text/javascript"
				data-domain-script={publicRuntimeConfig?.NEXT_PUBLIC_ONETRUST_KEY}
				data-document-language="true"
				strategy="afterInteractive"
			/>

			<Script
				id="onetrust-optanon-wrapper"
				type="text/javascript"
				dangerouslySetInnerHTML={{
					__html: 'function OptanonWrapper(){}',
				}}
			/>
			{publicRuntimeConfig?.NEXT_PUBLIC_CUSTOMER_SERVICE_CHAT_ID && (
				<Script
					id="customerChat"
					strategy="lazyOnload"
					src={`https://account.psplugin.com/${publicRuntimeConfig.NEXT_PUBLIC_CUSTOMER_SERVICE_CHAT_ID}/engage.js`}
				/>
			)}
			{publicRuntimeConfig?.NEXT_PUBLIC_VIDEOLY_ID && (
				<Script
					type="text/plain"
					id="videoly"
					className="optanon-category-C0001-C0002"
					src={`https://api.videoly.co/1/quchbox/0/${publicRuntimeConfig.NEXT_PUBLIC_VIDEOLY_ID}/quch.js`}
				/>
			)}
		</>
	);
}
ConsentAndAnalytics.displayName = 'ConsentAndAnalytics';

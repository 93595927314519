// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconLocation(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M12 2C8.16 2 5 5.16 5 9c0 4.17 4.42 9.92 6.23 12.11a1 1 0 0 0 1.53 0C14.58 18.92 19 13.17 19 9c0-3.84-3.16-7-7-7Zm3.38 3.2a.59.59 0 0 1 .38.9c-.07.18-.22.2-.38.23-.1.01-.2.04-.28.1-.15.22-.23.48-.24.75-.1.6-.11 1.41-.39 3.05a3.56 3.56 0 0 1-3.57 2.76h-.63C9.35 12.95 8 12.37 8 11.25c0-.8.66-1.46 1.46-1.46.13-.01.25 0 .38.03a1 1 0 0 1 .88.97c-.03.4-.33.5-.23.69.08.13.24.2.4.18.43-.08.68-.61.94-2.18a25.47 25.47 0 0 0 .22-2.97c-.1-.08-.4-.05-.54-.07a.39.39 0 0 1-.29-.34c0-.12 0-.23.02-.35.01-.47.62-.72 1.92-.75h1.3c.32.01.63.08.92.2Z" />
		</svg>
	);
}
IconLocation.displayName = 'IconLocation';

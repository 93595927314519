// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconReference(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M12 14a2 2 0 0 0 1.41-3.41 2 2 0 1 0-2.82 2.82A1.93 1.93 0 0 0 12 14zm-4 4h8v-.57a2 2 0 0 0-1.23-1.85 7.56 7.56 0 0 0-1.33-.43 6.96 6.96 0 0 0-2.88 0 7.56 7.56 0 0 0-1.34.42A2 2 0 0 0 8 17.44zm10.5 4h-13A1.54 1.54 0 0 1 4 20.5v-17a1.44 1.44 0 0 1 .45-1.05A1.44 1.44 0 0 1 5.5 2h7.9a1.46 1.46 0 0 1 .59.13 1.56 1.56 0 0 1 .48.32l5.08 5.08a1.56 1.56 0 0 1 .33.48 1.46 1.46 0 0 1 .12.59v11.9a1.54 1.54 0 0 1-1.5 1.5z" />
		</svg>
	);
}
IconReference.displayName = 'IconReference';

import type { InventoryItem } from 'models/inventory';
import { arrayUnique } from 'utils/collection';
import fetchData, { API_URL } from 'utils/fetchData';
import { asArray, is } from 'utils/helpers';
import { createUrl } from 'utils/url';

export const fetchStock = (
	variantIds: string | string[] | undefined,
	storeId?: string | string[],
): Promise<InventoryItem[] | undefined> => {
	const filteredVariantIds = arrayUnique(asArray(variantIds).filter(is.truthy));

	const apiUrl = createUrl(`${API_URL}ProductStock`, { storeId });

	return fetchData<InventoryItem[]>(apiUrl, {
		method: 'POST',
		body: JSON.stringify(filteredVariantIds),
	});
};

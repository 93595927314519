// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconVerifiedUser(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="m9.829 16.778-.707-.707-2.829-2.828a1 1 0 0 1 0-1.414 1 1 0 0 1 1.415 0l2.828 2.828L16.9 8.293a1 1 0 0 1 1.415 0 1 1 0 0 1 0 1.415l-6.364 6.363-.707.707a1 1 0 0 1-.707.293 1 1 0 0 1-.708-.293z" />
			<path d="M12 24a11.922 11.922 0 0 1-8.486-3.515A11.922 11.922 0 0 1 0 12a11.921 11.921 0 0 1 3.515-8.485A11.922 11.922 0 0 1 12 0a11.921 11.921 0 0 1 8.485 3.515A11.921 11.921 0 0 1 24 12a11.922 11.922 0 0 1-3.515 8.486A11.921 11.921 0 0 1 12 24zm0-22A10.012 10.012 0 0 0 2 12a10.012 10.012 0 0 0 10 10 10.012 10.012 0 0 0 10-10A10.011 10.011 0 0 0 12 2z" />
		</svg>
	);
}
IconVerifiedUser.displayName = 'IconVerifiedUser';

/**
 * NOTE! This file is imported in the Tailwind config which does not go through
 * the main TypeScript setup. Importing things like utils is not possible here.
 */

// TODO: check if all colors are used, and remove unused ones.
export const COLORS = {
	// https://www.hexcolortool.com/ for finding the hex for the hover/darken state of the buttons.
	// Brand colors:
	julaLogotypeRed: '#d22630',
	julaRedLight: '#DA3A35',
	julaRed: '#cb333b',
	julaRedDarken: '#b72e35', // darken by 5%
	julaRedDark: '#B32013',
	julaRedDarkDarken: '#9c1c11',
	julaPriceTagRed: '#c55a56',

	// Section colors:
	toolsMachines: '#41b6e6',
	constructionPaint: '#0077c8',
	electricsLightning: '#ffb81c',
	clothesSafety: '#ff8200',
	garden: '#c4d600',
	leisure: '#509e2f',
	autoGarage: '#686e9f',
	homeHousehold: '#6d2077',

	// Grey:
	white: '#ffffff',
	whiteDarken: '#f2f2f2',
	whiteDarkenPlus: '#e5e5e5',

	greyLighter: '#f5f5f5',
	greyLighterDarken: '#e8e8e8',
	greyLighterDarkenPlus: '#dcdcdc',

	greyLight: '#ededed',
	greyLightDarken: '#d4d4d4',

	grey: '#b3b3b3',
	greyDarken: '#A6A6A6', // darken by 5%
	greyDarkenPlus: '#9A9A9A', // darken by 10%

	greyDark: '#808080',
	greyDarkDarken: '#737373', // darken by 5%
	greyDarkDarkenPlus: '#676767', // darken by 10%

	greyDarker: '#333333',
	greyDarkerDarken: '#262626', // darken by 5%
	greyDarkerLighten: '#373737', // lighten by 5%

	black: '#111111',
	blackLighten: '#1c1c1c',

	// Other colors:
	cta: '#31a63d',
	ctaDarken: '#2b9236', // darken by 5%
	ctaDarkenPlus: '#188D24', // darken by 10%
	ctaDarkenMax: '#0B8017', // darken by 15%
	star: '#ffd100',
	warning: '#fffcf4',
	campaign: '#ffe500',
	information: '#fff7d3',
	highlight: '#fff7d3',
	error: '#cb333b',
	errorLighter: '#faeaeb',
	success: '#31a63d',
	successLighter: '#e7f3e8',
	news: '#ffa300',

	// Opacity:
	blackOpacity0: 'rgba(0, 0, 0, 0)',
	blackOpacity20: 'rgba(17, 17, 17, 0.2)',
};

function mapColors<T extends object>(obj: T, mapper: (key: keyof T) => string) {
	const result = {} as Record<keyof T, string>;
	Object.keys(obj).forEach((key) => {
		result[key] = mapper(key as keyof T);
	});
	return result;
}

export const BACKGROUND_COLOR_CLASSES = mapColors(COLORS, (key) => `bg-${key}`);

export const TEXT_COLOR_CLASSES = mapColors(COLORS, (key) => `text-${key}`);

// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconClear(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M12 22A10.011 10.011 0 0 1 2 12 10.011 10.011 0 0 1 12 2a10.011 10.011 0 0 1 10 10 10.011 10.011 0 0 1-10 10zm0-8.6 2.805 2.806a.986.986 0 0 0 .7.291.985.985 0 0 0 .7-.291.994.994 0 0 0 0-1.4L13.4 12l2.806-2.807a.985.985 0 0 0 .29-.7.985.985 0 0 0-.29-.7.986.986 0 0 0-.7-.291.984.984 0 0 0-.7.291L12 10.6 9.193 7.794a.984.984 0 0 0-.7-.291.986.986 0 0 0-.7.291.993.993 0 0 0 0 1.4L10.6 12l-2.807 2.806a.994.994 0 0 0 0 1.4.988.988 0 0 0 .7.29.985.985 0 0 0 .7-.29L12 13.4z" />
		</svg>
	);
}
IconClear.displayName = 'IconClear';

import { type Reducer, useCallback, useEffect, useReducer } from 'react';
import { useRouter } from 'next/router';

import type { BasePaginatedResponse } from 'models/api';
import { TupleSet } from 'utils/collection';
import { assertUnreachable, is } from 'utils/helpers';

import { useLayoutServicePlaceholder } from './useLayoutServicePlaceholder';
import { usePrevious } from './usePrevious';

interface Params<ItemT, InitialComponentT, ItemKeyT> {
	firstPageItemCountOffset?: number;
	initialComponent?: InitialComponentT;
	initialItems?: ItemT[];
	initialNextPageOffset: number;
	initialQueryVars?: [string, string][];
	itemsKey: ItemKeyT;
	offsetQueryVarName: string;
	pageSizeQueryVarName: string;
	placeholderComponentName: string;
}

type QueryVars = TupleSet<string, string>;
interface State<ItemT> {
	firstPageItemCountOffset: number;
	futureNextPageOffset: number;
	initialNextPageOffset: number;
	isActive: boolean;
	items: ItemT[];
	itemsUpdateType: 'append' | 'replace';
	nextPageOffset: number | null;
	queryVars: QueryVars;
}
type Action<ItemT> =
	| { type: 'LOAD_NEXT_PAGE' }
	| { type: 'SET_ITEMS'; futureNextPageOffset?: number; items: ItemT[] }
	| { type: 'UPDATE_QUERY_VARS'; queryVars: QueryVars };

function reducer<ItemT>(
	state: State<ItemT>,
	action: Action<ItemT>,
): State<ItemT> {
	switch (action.type) {
		case 'LOAD_NEXT_PAGE':
			if (state.futureNextPageOffset === state.nextPageOffset) {
				return state;
			}
			return {
				...state,
				nextPageOffset:
					state.futureNextPageOffset + state.firstPageItemCountOffset,
				// Only relevant on the first page, obvously.
				firstPageItemCountOffset: 0,
				isActive: true,
				itemsUpdateType: 'append',
			};

		case 'SET_ITEMS':
			return {
				...state,
				futureNextPageOffset:
					action.futureNextPageOffset ?? state.futureNextPageOffset,
				items:
					state.itemsUpdateType === 'append'
						? [...state.items, ...action.items]
						: action.items,
			};

		case 'UPDATE_QUERY_VARS':
			// Reset offsets to start on first page again.
			return {
				...state,
				itemsUpdateType: 'replace',
				futureNextPageOffset: state.initialNextPageOffset,
				isActive: true,
				nextPageOffset: null,
				queryVars: action.queryVars,
			};

		default:
			return assertUnreachable(action);
	}
}

/**
 * Handles filtering and pagination for a list of items that uses the
 * `hasNextPage` and `nextPageOffset` API. Requests are made to the page itself.
 *
 * Is controlled by query variables, similar to URLSearchParams, but works in
 * 'reverse' where an update to these variables will trigger a request that
 * updates the URL when done. The variables are updated with `updateQueryVars`
 * and any changes will be available immediately for things like `hasQueryVar`
 * and `queryVarItems`, to allow optimistic updates of the UI.
 *
 * The `component` field can be used to read things other than the items from
 * the response, like `categories` and `mainHeading` in the example below.
 * Since `component` is only fetched when a query variable has been updated
 * it's undefined on the initial page load - `initialComponent` data can be
 * passed to give `component` a fallback before any updates have been made.
 *
 * Is assumed to be used on a static page so if `initialItems` changes, the
 * hook's state will reset.
 *
 * @example
 *
 * interface Article {
 *   title: string;
 *   author: string;
 * }
 * interface ArticleList extends BasePaginatedResponse {
 *   articles: Article[];
 *   categories: string[];
 *   mainHeading: string;
 * }
 * type InitialArticleList = Omit<ArticleList, 'articles'>
 *
 * function Articles({
 *   articles: initialArticles,
 *   categories: initialCategories,
 *   mainHeading: initialMainHeading,
 *   nextPageOffset: initialNextPageOffset,
 * }) {
 *   const {
 *     component: articleList,
 *     getQueryVarValue,
 *     hasQueryVar,
 *     isLoading,
 *     items: articles,
 *     loadMore: loadMoreArticles,
 *     updateQueryVars,
 *   } = usePagination<Article, ArticleList, InitialArticleList>({
 *     initialComponent: {
 *       categories: initialCategories,
 *       mainHeading: initialMainHeading,
 *     },
 *     initialItems: initialArticles,
 *     initialNextPageOffset,
 *     itemsKey: 'articles',
 *     offsetQueryVarName: 'articles-offset',
 *     pageSizeQueryVarName: 'article-page-size',
 *     placeholderComponentName: 'ArticlesList',
 *   });
 *   const currentCategory = getQueryVarValue('cat');
 *   const categories = articleList.categories;
 *
 *   return (
 *     <div>
 *       <h1>{mainHeading}: {currentCategory}</h1>
 *       {categories.map((category) => (
 *         <Button
 *           onClick={() => {
 *             updateQueryVars((vars) => { vars.set('cat', category) });
 *           }}
 *           isCurrent={hasQueryVar('cat', category)}>
 *           text={category}
 *         />
 *       ))}
 *       <div>{articles.map(...)}</div>
 *       <Button
 *         onClick={loadMoreArticles}
 *         showSpinner={isLoading}
 *         text="Load more articles"
 *       />
 *     </div>
 *   );
 * }
 */
export function usePagination<
	ItemT,
	ResponseT extends BasePaginatedResponse,
	InitialComponentT extends object = ResponseT,
	ItemKeyT extends keyof ResponseT = keyof ResponseT,
>({
	firstPageItemCountOffset = 0,
	initialComponent,
	initialItems = [],
	initialNextPageOffset,
	initialQueryVars = [],
	itemsKey,
	offsetQueryVarName,
	pageSizeQueryVarName,
	placeholderComponentName,
}: Params<ItemT, InitialComponentT, ItemKeyT>) {
	const router = useRouter();

	const [
		{ isActive, items, itemsUpdateType, nextPageOffset, queryVars },
		dispatch,
	] = useReducer<Reducer<State<ItemT>, Action<ItemT>>>(reducer, {
		firstPageItemCountOffset,
		futureNextPageOffset: initialNextPageOffset,
		initialNextPageOffset,
		isActive: false,
		items: initialItems,
		itemsUpdateType: 'append',
		nextPageOffset: null,
		queryVars: new TupleSet<string, string>(
			// Ignore any mistakenly added offset key.
			...initialQueryVars.filter(([key]) => key !== offsetQueryVarName),
		),
	});

	const offsetParam = nextPageOffset
		? `${offsetQueryVarName}=${nextPageOffset}`
		: '';
	// Sort a new TupleSet to avoid mutation.
	const queryVarsParams =
		queryVars.size > 0 ? new TupleSet(queryVars).sort().toQueryString() : '';
	const queryVarsString = [offsetParam, queryVarsParams]
		.filter(Boolean)
		.join('&');

	const { component, isLoading, error } =
		useLayoutServicePlaceholder<ResponseT>('jula-main', {
			componentName: placeholderComponentName,
			isActive,
			// Ignore any existing query vars.
			path: router.asPath.split('?')[0],
			queryVars: queryVarsString,
		});

	const updateUrl = useCallback(
		(pageSize: number) => {
			const baseUrl = globalThis.location?.href.split('?')[0];
			const params = [
				pageSize === initialNextPageOffset || !pageSize
					? ''
					: `${pageSizeQueryVarName}=${pageSize}`,
				queryVarsParams,
			]
				.filter(Boolean)
				.join('&');
			// Params may be empty, avoid ending the URL with a question mark.
			const newUrl = [baseUrl, params].filter(Boolean).join('?');
			router
				.replace(newUrl, undefined, { scroll: false, shallow: true })
				.catch((replaceError) => {
					console.error(`Pagination updateUrl error: ${replaceError}`);
				});
		},
		[initialNextPageOffset, pageSizeQueryVarName, queryVarsParams, router],
	);

	// Set state when a new response is available. Not using the `onSuccess`
	// callback since that only triggers for requests, not cached data.
	const prevComponent = usePrevious(component);
	useEffect(() => {
		if (component && component !== prevComponent) {
			dispatch({
				type: 'SET_ITEMS',
				items: is.array(component[itemsKey])
					? (component[itemsKey] as ItemT[])
					: ([] as ItemT[]),
				futureNextPageOffset: component.hasNextPage
					? component.nextPageOffset
					: undefined,
			});
			updateUrl(component.nextPageOffset);
		}
	}, [component, itemsKey, prevComponent, itemsUpdateType, updateUrl]);

	const loadMore = useCallback(() => {
		dispatch({ type: 'LOAD_NEXT_PAGE' });
	}, []);

	const updateQueryVars = useCallback(
		(updater: (vars: typeof queryVars) => void) => {
			const newVars = new TupleSet(queryVars);
			updater(newVars);
			// Remove any mistakenly added offset key.
			newVars.delete(offsetQueryVarName);
			dispatch({ type: 'UPDATE_QUERY_VARS', queryVars: newVars });
		},
		[offsetQueryVarName, queryVars],
	);

	const getQueryVarValue = useCallback(
		(key: string) => queryVars.get(key),
		[queryVars],
	);

	const hasQueryVar = useCallback(
		(key: string, value?: string) => queryVars.has(key, value),
		[queryVars],
	);

	return {
		component: component ?? initialComponent,
		error,
		/** Get the value, if any, for the specified key. */
		getQueryVarValue,
		/** Check if the specified key or key-value pair is set. */
		hasQueryVar,
		isLoading,
		items,
		/** Load more items for the current selection. */
		loadMore,
		/** Query var key-value pairs. */
		queryVarItems: queryVars.items as readonly [string, string][],
		/** Update filtering parameters to trigger a request for new data. */
		updateQueryVars,
	};
}

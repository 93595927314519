// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconJulaJ(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path
				d="M18.763 4.402a1.169 1.169 0 0 1 .759 1.782c-.14.377-.446.409-.771.471a1.284 1.284 0 0 0-.548.207 2.867 2.867 0 0 0-.492 1.512c-.176 1.178-.22 2.814-.764 6.087-.666 4-4.641 5.522-7.148 5.522a9.766 9.766 0 0 1-1.269 0C6.695 19.914 4 18.743 4 16.513a2.929 2.929 0 0 1 2.918-2.928 2.647 2.647 0 0 1 .755.052A2 2 0 0 1 9.44 15.59c-.071.781-.662 1-.467 1.373a.832.832 0 0 0 .806.362c.865-.162 1.371-1.22 1.886-4.365.176-1.064.414-3.658.414-4.049a7.408 7.408 0 0 0 .034-1.88c-.206-.172-.813-.105-1.085-.147a.774.774 0 0 1-.582-.684 2.672 2.672 0 0 1 .038-.687c.025-.941 1.232-1.452 3.829-1.507l2.618.006a5.12 5.12 0 0 1 1.83.392z"
				fill-rule="evenodd"
			/>
		</svg>
	);
}
IconJulaJ.displayName = 'IconJulaJ';

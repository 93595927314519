// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconUsers(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M21.2 21H20a2.08 2.08 0 0 0-.02-.25 9.97 9.97 0 0 0-5.58-7.74 8.08 8.08 0 0 1 5.1 2.14A7.98 7.98 0 0 1 22 20a.93.93 0 0 1-.23.73.76.76 0 0 1-.57.27zM14 11a4.08 4.08 0 0 1-.62-.05 6 6 0 0 0 1.94-7.73A4 4 0 0 1 14 11z" />
			<path d="M1.9 22a.97.97 0 0 1-.9-1.12 9.07 9.07 0 0 1 17.99 0 .96.96 0 0 1-.9 1.12zM5 6a5 5 0 1 1 5 5 5 5 0 0 1-5-5z" />
		</svg>
	);
}
IconUsers.displayName = 'IconUsers';

import Router from 'next/router';
import {
	parse as parseUrlQuery,
	stringify as stringifyUrlQuery,
} from 'query-string';
import { assign, createMachine, spawn } from 'xstate';

import { ActionButtonState } from 'components/ActionButton';
import {
	ApiJulaModelsCartCartErrorModelTypes,
	ApiJulaModelsCartCartResponseModel,
	type ApiJulaModelsCheckoutPaymentDetailsRequest,
} from 'models/api';
import { ExtendedResponseError } from 'utils/fetchData';
import { pushToGTM } from 'utils/GoogleTagManager';
import { sendGlobalEvent } from 'utils/helpers';
import Mutex from 'utils/mutex';

import { deliveryMachine } from './delivery/delivery.machine';
import { giftCardBonusMachine } from './giftCardBonus/giftCardBonus.machine';
import { paymentMachine } from './payment/payment.machine';
import { userInfoMachine } from './userInfo/userInfo.machine';
import {
	copyCart,
	requestCheckoutApi,
	verifyRedirectPayment,
	waitForUserInfoActor,
} from './checkout.machine.services';
import {
	CheckoutMachineContext,
	CheckoutMachineEvents,
	CheckoutServices,
} from './checkout.machine.types';
import { contactReferencesMachine } from './contactReferences';

/**
 * Request mutex
 * - used to make the updatedCart request synchronous.
 */
const requestMutex = new Mutex();

/**
 * checkout machine
 */
export const checkoutMachine = createMachine(
	{
		preserveActionOrder: true, // This is required for this to work
		context: {
			cartId: '',
			// Child actors should technically be nullable but they're created on
			// entry so pretend they're not to keep usage simpler. Using ts-ignore
			// will disable typing for every context property so use any instead.
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			paymentActor: null as any,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			deliveryActor: null as any,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			userInfoActor: null as any,
			contactReferencesActor: null,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			giftCardBonusActor: null as any,
			showLogin: false,
			payButtonState: 'idle',
			failedLoadingRetries: 0,
			showNotification: false,
			shouldInitCheckout: false,
		},
		tsTypes: {} as import('./checkout.machine.typegen').Typegen0,
		schema: {} as {
			context: CheckoutMachineContext;
			events: CheckoutMachineEvents;
			services: CheckoutServices;
		},
		id: 'checkoutMachine',
		initial: 'initialization',
		states: {
			initialization: {
				description:
					'This state is run on startup, here wait for the auth machine to finish, set data about the user provided by the auth machine, and if we have been redirected from a payment provider we set data from the url',
				initial: 'startUpChildMachines',
				states: {
					startUpChildMachines: {
						description:
							'Entry state, spawn children, set errors if any, unfortunately we also need to check that we have a window object since the guards are "run" on server side as well and are dependent on the window',
						entry: ['spawnChildMachines', 'setCheckoutErrorMessages'],
						always: { target: 'checkUrl', cond: 'windowExists' },
					},
					checkUrl: {
						description:
							'Check if we have been redirect from a payment provider and set that data from the url',
						always: [
							{
								target: '#loadCart',
								description:
									'If we have been redirected from a payment provider we set the data from the url',
								cond: 'shouldSetUrlVerifyPaymentRedirectData',
								actions: [
									'setVerifyPaymentDataFromRedirectUrl',
									'removeVerifyPaymentRedirectFromUrl',
								],
							},
							{
								target: '#loadCart',
								cond: 'shouldSetRedirectCartId',
								description:
									'If a user has pressed the back button from a payment provider we set the cartId from the url to get back where we were',
								actions: 'setIdFromRedirectResultUrl',
							},
							{
								target: '#loadCart',
							},
						],
					},
				},
			},
			loadCart: {
				id: 'loadCart',
				description:
					'When auth is done we load the cart with different methods depending on how the user got here',
				initial: 'determineHowToLoadCart',
				states: {
					determineHowToLoadCart: {
						description:
							'Decide which method to use for load the cart (the order in always is important!)',
						always: [
							{ target: 'verifyPayment', cond: 'shouldVerifyPayment' },
							{ target: 'getCartWithId', cond: 'hasCartId' },
							{ target: 'getCartWithPost' },
						],
					},
					verifyPayment: {
						id: 'verifyPayment',
						description:
							'When returning from a redirect payment with id and payload we load the cart with verifyPayment',
						exit: 'resetVerifyPaymentData',
						initial: 'requestVerifyPayment',
						states: {
							requestVerifyPayment: {
								id: 'requestVerifyPayment',
								invoke: {
									src: 'verifyPayment',
									id: 'verifyPayment',
									onDone: {
										target: '#checkingCartStatus',
										actions: ['setData', 'setCheckoutErrorMessages'],
									},
									onError: [
										{
											cond: 'shouldVerifyPayment',
											target: 'retry',
										},
										{
											target: '#checkoutMachine.error',
										},
									],
								},
							},
							retry: {
								description: 'If a request has failed, we retry 5 times',
								always: [
									{
										target: '#error',
										cond: 'retriedToManyTimes',
										actions: ['resetFailedLoadingRetries'],
									},
								],
								after: {
									2000: {
										target: '#verifyPayment',
										internal: true,
										actions: ['incFailedLoadingRetries'],
									},
								},
							},
						},
					},
					getCartWithId: {
						id: 'getCartWithId',
						description:
							'Load a specific cart with an id, can be the current cart that we need to reload or an id from localStorage or the url',
						invoke: {
							id: 'getCartWithId',
							src: 'getCartWithId',
							onDone: {
								target: 'checkingCartStatus',
								actions: ['setData', 'setCheckoutErrorMessages'],
							},
							onError: {
								target: '#error',
							},
						},
					},
					getCartWithPost: {
						description:
							'If we dont have any id we load the cart with a POST (which gives us a new cart or the current users last used cart)',
						invoke: {
							src: 'getCartWithPost',
							id: 'getCartWithPost',
							onDone: {
								target: 'checkingCartStatus',
								actions: ['setData', 'setCheckoutErrorMessages'],
							},
							onError: {
								target: '#error',
							},
						},
					},
					checkingCartStatus: {
						id: 'checkingCartStatus',
						description:
							'Checks the status of the newly loaded cart to decide what to do next',
						entry: 'notifyChildrenCartIsUpdated',
						always: [
							{
								target: '#loadCart.getCartWithPost',
								actions: ['goToOrderConfirmationPage'],
								cond: 'orderFinalize',
							},
							{
								target: '#idle',
								cond: 'shouldFinalizeOrder',
								actions: ['notifyPaymentMachineToFinalizePayment'],
							},
							{
								target: '#copyCart',
								cond: 'paymentFailed',
								actions: [
									'setPaymentFailedErrorMessages',
									'showNotifications',
									'setPayButtonStateToFailed',
								],
							},
							{
								target: 'pollCart',
								cond: 'waitingForPayment',
							},
							{
								target: '#idle',
							},
						],
					},
					pollCart: {
						description:
							'If we have a paymentPending we poll the cart until its finished.',
						after: {
							4000: { target: 'getCartWithId' },
						},
					},
				},
				on: {
					ABORT_PAYMENT_PENDING: {
						target: 'copyCart',
						actions: [
							'setPaymentFailedErrorMessages',
							'showNotifications',
							'removeCartIdFromUrl',
						],
					},
				},
			},
			copyCart: {
				description:
					'If payment failed or any other error after requesting checkout we need to copy the cart to be able to make adjustments to it',
				id: 'copyCart',
				invoke: {
					src: 'copyCart',
					id: 'copyCart',
					onDone: {
						actions: 'setData',
						target: '#idle',
					},
					onError: {
						description:
							'If get get 500 type error we assume server has issues and check if we should try again',
						target: 'error',
						cond: 'maybeTemporaryError',
					},
				},
			},
			updateCart: {
				id: 'updateCart',
				description: 'Update the cart with new data',
				on: {
					INIT_CHECKOUT: {
						actions: ['setPayButtonStateToLoading', 'setShouldInitCheckout'],
					},
					UPDATE_CART: {
						actions: 'updateData',
						target: 'updateCart',
					},
				},
				invoke: {
					src: 'updateCart',
					id: 'updateCart',
					onDone: [
						{
							actions: ['setData', 'setCheckoutErrorMessages'],
							target: '#idle',
						},
					],
					onError: [
						{
							actions: ['notifyChildToReset'],
							target: '#error',
						},
					],
				},
			},
			error: {
				id: 'error',
				description:
					'General error state that holds retry logic, if we have an Id we do copy cart, since that endpoint can handle both if we have a cart that is ok or not',
				entry: ['setPayButtonStateToFailed', 'incFailedLoadingRetries'],
				initial: 'checkFailedRetries',
				states: {
					checkFailedRetries: {
						always: {
							target: 'retry',
							cond: 'shouldRetryRequest',
						},
					},
					retry: {
						after: {
							RETRY_DELAY: [
								{
									target: '#copyCart',
									cond: 'hasCartId',
								},
								{
									target: '#loadCart',
								},
							],
						},
					},
				},
				on: {
					UPDATE_CART: {
						actions: 'updateData',
						target: 'updateCart',
					},
				},
			},
			idle: {
				id: 'idle',
				entry: 'notifyChildrenCartIsUpdated',
				exit: 'unSetShouldInitCheckout',
				always: {
					target: '#checkoutAndPaymentFlow',
					cond: 'shouldInitiateCheckout',
				},
				on: {
					INIT_CHECKOUT: {
						target: '#checkoutAndPaymentFlow',
					},
					UPDATE_CART: {
						actions: 'updateData',
						target: 'updateCart',
					},
					PAYMENT_DONE: {
						target: '#paymentDone',
						actions: 'setOrderData',
					},
				},
			},
			checkoutAndPaymentFlow: {
				id: 'checkoutAndPaymentFlow',
				description: 'We are in checkout state',
				initial: 'prepareForCheckout',
				states: {
					prepareForCheckout: {
						description:
							'We set the payment button to loading and wait for any request still being in progess from userInfo, then we initiating checkout.',
						entry: ['setPayButtonStateToLoading', 'resetShowNotifications'],
						invoke: {
							id: 'waitForUserInfoActor',
							src: 'waitForUserActor',
							onDone: [
								{
									target: 'determinePaymentState',
									cond: 'readyToInitCheckout',
								},
								{
									target: 'checkoutAndPaymentFlowError',
								},
							],
							onError: [
								{
									target: 'checkoutAndPaymentFlowError',
								},
							],
						},
						on: {
							UPDATE_CART: {
								actions: ['updateData', 'setShouldInitCheckout'],
								target: '#updateCart',
							},
						},
					},
					determinePaymentState: {
						entry: 'checkPaymentState',
						description:
							'The payment machine holds the state for payment types with internal state, we need to check that these are ok before we proceed and initate checkout to not get a locked cart',
						on: {
							PAYMENT_STATE_ERROR: {
								target: 'checkoutAndPaymentFlowError',
								actions: [
									'setPayButtonStateToFailed',
									'setPaymentErrorMessages',
								],
							},
							PAYMENT_STATE_OK: {
								target: 'initiatingCheckout',
							},
						},
					},
					initiatingCheckout: {
						description:
							'User has clicked the buy-button we start the checkout process',
						invoke: {
							src: 'checkoutCart',
							id: 'checkoutCart',
							onDone: {
								target: 'determineCheckoutState',
								actions: [
									'setData',
									'notifyChildrenCartIsUpdated',
									'setCheckoutErrorMessages',
								],
							},
							onError: [
								{
									target: 'checkoutAndPaymentFlowError',
								},
							],
						},
					},
					determineCheckoutState: {
						always: [
							{
								description:
									'There is still something invalid with the checkoutstatus that was not caught bu the check before we initiate checkout, perhaps something more needs to happend than just going to checkoutError',
								cond: 'checkoutInvalid',
								target: 'checkoutAndPaymentFlowError',
							},
							{
								description:
									'Status is valid for checkout, but api detects change between this request and last PUT request and wants to notify user of this change and requires us to prompt them to INIT_CHECKOUT again',
								cond: 'cartIsValidButHasDiff',
								target: 'checkoutAndPaymentFlowError',
								actions: ['setShowCheckoutValidNotificationMessage'],
							},
							{
								target: 'initiatingPayment',
							},
						],
					},
					initiatingPayment: {
						entry: 'initPayment',
						description:
							'Initiate the payment by telling the payment actor to do so and wait for it to be done',
						on: {
							CHECK_CHECKOUT_STATUS: {
								target: '#loadCart',
							},
							PAYMENT_DONE: {
								target: 'paymentDone',
								actions: 'setOrderData',
							},
						},
					},
					paymentDone: {
						id: 'paymentDone',
						entry: ['goToOrderConfirmationPage'],
						description:
							'Payment is done, we load the cart to get the latest details',
						always: [
							{
								target: '#checkoutMachine.loadCart.getCartWithPost',
								actions: [
									'sendToGTM',
									'sendEngagement',
									'setPayButtonStateToSuccess',
								],
							},
						],
					},
					checkoutAndPaymentFlowError: {
						description: 'Error state',
						always: [
							{
								target: '#idle',
								actions: ['setPayButtonStateToFailed', 'showNotifications'],
							},
						],
						on: {
							UPDATE_CART: {
								actions: 'updateData',
								target: '#updateCart',
							},
						},
					},
				},
			},
		},
		on: {
			// There is a VERIFY_REDIRECT_PAYMENT that is sent to the payment machine that in turns sends this
			// when it gets it, maybe that flow can be simplified or made clearer
			VERIFY_REDIRECT_PAYMENT: {
				target: 'loadCart',
				actions: 'setVerifyPaymentData',
			},
			PAYMENT_FAILED: {
				target: 'copyCart',
				actions: ['setPayButtonStateToFailed'],
			},
			RESET_SHOW_NOTIFICATION: {
				actions: 'resetShowNotifications',
			},
		},
	},
	{
		actions: {
			sendToGTM: (context) => {
				if (!context.orderData) return;

				const selectedDeliveryName =
					context.orderData.availableDeliveryMethods?.find(
						(deliveryMethod) =>
							deliveryMethod.id ===
							context.orderData?.selectedDelivery?.deliveryMethodId,
					)?.name || '';
				pushToGTM({
					type: 'add_shipping_info',
					payload: {
						shippingTier: selectedDeliveryName,
						products: context.orderData.variants,
					},
				});
				pushToGTM({
					type: 'add_payment_info',
					payload: {
						paymentType: context.orderData.selectedPayment
							?.selectedPaymentType as string,
						products: context.orderData.variants,
					},
				});
				pushToGTM({
					type: 'purchase',
					payload: {
						cart: context.orderData,
					},
				});
			},
			sendEngagement: (context) => {
				const total = context.orderData?.summaries.find(
					(sum) => sum.sumType === 'Total',
				);

				sendGlobalEvent('engagement', {
					type: 'completePurchase',
					data: {
						total: total?.value ?? 0,
					},
				});
			},
			spawnChildMachines: assign({
				deliveryActor: (context, _event) =>
					context.deliveryActor || spawn(deliveryMachine),
				paymentActor: (context, _event) =>
					context.paymentActor || spawn(paymentMachine),
				userInfoActor: (context, _event) =>
					context.userInfoActor || spawn(userInfoMachine),
				giftCardBonusActor: (context, _event) =>
					context.giftCardBonusActor || spawn(giftCardBonusMachine),
				contactReferencesActor: (context, _event) =>
					context.contactReferencesActor || spawn(contactReferencesMachine),
			}),

			setCheckoutErrorMessages: assign({
				checkoutErrorMessages: (context, _event) => {
					const errors: string[] = [];
					const errorKeys: ApiJulaModelsCartCartErrorModelTypes[] = [
						'CartIsEmpty',
						'ContactReferenceNotSelected',
						'AddressFirstNameInvalidLength',
						'AddressFirstNameInvalidChar',
						'AddressLastNameInvalidLength',
						'AddressLastNameInvalidChar',
						'AddressAddressInvalidLength',
						'AddressAddressInvalidChar',
						'AddressCoAddressInvalidLength',
						'AddressCoAddressInvalidChar',
						'AddressPostalCodeInvalidLength',
						'AddressPostalCodeInvalid',
						'AddressCityInvalidLength',
						'AddressCityInvalidChar',
						'DeliveryMethodInvalid',
						'DeliveryMethodNotSelected',
						'DiscountCombinationInvalid',
						'EmailInvalid',
						'EmailInvalidForCustomer',
						'PaymentMethodInvalid',
						'PaymentMethodNotSelected',
						'PickupLocationInvalid',
						'PickupLocationNotSelected',
						'TelephoneNumberInvalid',
					];
					if (context?.data?.errorList) {
						context.data.errorList.forEach((error) => {
							if (error.type && errorKeys.includes(error.type)) {
								errors.push(error.description);
							}
						});
					}
					return errors;
				},
			}),
			setPaymentErrorMessages: assign({
				checkoutErrorMessages: (context, event) => [
					...(context?.checkoutErrorMessages || []),
					event.error,
				],
			}),
			setPaymentFailedErrorMessages: assign({
				checkoutErrorMessages: (context) => [
					...(context.checkoutErrorMessages || []),
					'checkout_payment_failed_text',
				],
			}),

			notifyChildrenCartIsUpdated: (context) => {
				context.deliveryActor.send({
					type: 'CART_UPDATED',
					value: context.data,
				});
				context.paymentActor.send({
					type: 'CART_UPDATED',
					value: context.data,
				});
				context.userInfoActor.send({
					type: 'CART_UPDATED',
					value: context.data,
				});
				context.giftCardBonusActor.send({
					type: 'CART_UPDATED',
					value: context.data,
				});
				context.contactReferencesActor.send({
					type: 'CART_UPDATED',
					value: context.data,
				});
			},
			initPayment: (context) => {
				context.paymentActor.send({
					type: 'INIT_PAYMENT',
				});
			},
			checkPaymentState: (context) => {
				context.paymentActor.send({
					type: 'CHECK_PAYMENT_STATE',
				});
			},
			setShowCheckoutValidNotificationMessage: assign({
				checkoutErrorMessages: (context, _event) => {
					let errors: string[] = [];
					if (
						context?.checkoutErrorMessages &&
						context.checkoutErrorMessages.length > 0
					) {
						errors = [...context.checkoutErrorMessages];
					}
					errors.push('CheckoutValidWithDiffNotification');
					return errors;
				},
			}),
			setVerifyPaymentDataFromRedirectUrl: assign({
				verifyPaymentData: (_context) => {
					const urlQuery = parseUrlQuery(window.location.search);
					const redirectResult = Array.isArray(urlQuery.redirectResult)
						? urlQuery.redirectResult[0]
						: urlQuery.redirectResult;
					const paymentDetails:
						| ApiJulaModelsCheckoutPaymentDetailsRequest
						| undefined = redirectResult
						? {
								details: { redirectResult },
							}
						: undefined;

					return {
						verifyPayment: true,
						paymentDetails,
					};
				},
				cartId: (_context) => {
					const urlQuery = parseUrlQuery(window.location.search);
					const cartId = Array.isArray(urlQuery.cartId)
						? urlQuery.cartId[0]
						: urlQuery.cartId;
					if (!cartId) return undefined;
					return cartId;
				},
			}),
			removeVerifyPaymentRedirectFromUrl: (_context) => {
				const currentPath = window.location.pathname;
				const currentQuery = window.location.search;
				const urlQuery =
					currentQuery === undefined ? {} : parseUrlQuery(currentQuery);
				delete urlQuery.verifyRedirectPayment;
				delete urlQuery.redirectResult;
				window.history.replaceState(
					{},
					'',
					`${currentPath}?${stringifyUrlQuery(urlQuery)}`,
				);
			},
			setVerifyPaymentData: assign({
				verifyPaymentData: (_context, event) => ({
					verifyPayment: true,
					paymentDetails: event.payload,
				}),
			}),
			resetVerifyPaymentData: assign({
				verifyPaymentData: (_context) => undefined,
			}),
			setIdFromRedirectResultUrl: assign({
				cartId: (_context, _event) => {
					const urlQuery = parseUrlQuery(window.location.search);
					const cartId = Array.isArray(urlQuery.cartId)
						? urlQuery.cartId[0]
						: urlQuery.cartId;
					if (!cartId) return undefined;
					return cartId;
				},
			}),

			setData: assign({
				data: (_context, event) => event.data || null,
				newData: (_context, _event) => undefined,
				cartId: (_, event) => event.data.id,
			}),

			notifyChildToReset: (context) => {
				context.paymentActor.send('RESET_TO_OLD');
				context.deliveryActor.send('RESET_TO_OLD');
				context.contactReferencesActor.send('RESET_TO_OLD');
			},

			updateData: assign({
				newData: (context, event) => {
					const { type, ...data } = event;
					return {
						...context.data,
						...context.newData,
						...data,
					} as ApiJulaModelsCartCartResponseModel;
				},
			}),

			setPayButtonStateToSuccess: assign({
				payButtonState: (_context) => 'success' as ActionButtonState,
			}),
			setPayButtonStateToFailed: assign({
				payButtonState: (_context) => 'failure' as ActionButtonState,
			}),
			setPayButtonStateToLoading: assign({
				payButtonState: (_context) => 'loading' as ActionButtonState,
			}),

			setOrderData: assign({
				orderData: (_context, event) => event.orderData,
				data: (_context, _event) => undefined,
			}),

			notifyPaymentMachineToFinalizePayment: (context) => {
				context.paymentActor.send({
					type: 'FINALIZE_PAYMENT',
				});
			},
			showNotifications: assign({
				showNotification: (_context, _event) => true,
			}),
			resetShowNotifications: assign({
				showNotification: (_context, _event) => false,
			}),
			resetFailedLoadingRetries: assign({
				failedLoadingRetries: (_context) => 0,
			}),
			incFailedLoadingRetries: assign({
				failedLoadingRetries: (context) => context.failedLoadingRetries + 1,
			}),
			removeCartIdFromUrl: (context, _event) => {
				const currentPath = window.location.pathname;
				const currentQuery = window.location.search;
				const urlQuery =
					currentQuery === undefined ? {} : parseUrlQuery(currentQuery);
				delete urlQuery.cartId;
				Router.replace(
					`${currentPath}?${stringifyUrlQuery(urlQuery)}`,
					undefined,
					{ shallow: true },
				);
			},
			goToOrderConfirmationPage: (context, _event) => {
				const id = context?.orderData?.id || context?.data?.id;
				Router.push(`/order/?orderId=${id}`);
			},
			setShouldInitCheckout: assign({
				shouldInitCheckout: (_context, _event) => true,
			}),
			unSetShouldInitCheckout: assign({
				shouldInitCheckout: (_context, _event) => false,
			}),
		},
		guards: {
			windowExists: (_context) => typeof window !== 'undefined',
			shouldSetUrlVerifyPaymentRedirectData: (_context) => {
				const urlQuery = parseUrlQuery(window.location.search);
				return Boolean(urlQuery.verifyRedirectPayment);
			},
			shouldSetRedirectCartId: (_context) => {
				const urlQuery = parseUrlQuery(window.location.search);
				return Boolean(urlQuery.cartId);
			},
			shouldVerifyPayment: (context) => Boolean(context?.verifyPaymentData),
			checkoutInvalid: (context, _event) =>
				context?.data?.checkoutStatus === 'Invalid',
			cartIsValidButHasDiff: (context, _event) =>
				context?.data?.checkoutStatus === 'Valid',
			// TODO: can probably be simplified since everything being checked after Invalid probably triggers the invalid state
			readyToInitCheckout: (context) =>
				context.data?.checkoutStatus !== 'Invalid',
			hasCartId: (context) => Boolean(context.cartId),
			retriedToManyTimes: (context) => context.failedLoadingRetries >= 6,
			shouldRetryRequest: (context) => context.failedLoadingRetries < 6,
			orderFinalize: (context) => context.data?.checkoutStatus === 'Finalized',
			shouldFinalizeOrder: (context) =>
				context.data?.checkoutStatus === 'Paid' ||
				context.data?.checkoutStatus === 'OrderCreated',
			paymentFailed: (context) =>
				context.data?.checkoutStatus === 'PaymentFailed',
			waitingForPayment: (context) =>
				context.data?.checkoutStatus === 'PaymentPending',
			shouldInitiateCheckout: (context) => context.shouldInitCheckout,
			maybeTemporaryError: (_, event) => {
				const { data } = event;
				const errorData = data as ExtendedResponseError;
				return errorData.status === 500 || errorData.status === 503;
			},
		},
		services: {
			updateCart: async (context, event) => {
				if (!context.data) {
					Promise.reject({ error: 'Cart is null' });
				}

				// TODO: borde vi har hantering baserat på state, och kanske egna services för att hantera checken här under?
				return requestMutex.dispatch(async () =>
					requestCheckoutApi('updateCart', {
						cart: context.newData!,
						getCodes: Boolean(
							event.type === 'UPDATE_CART' && event.discountCodes,
						),
					}),
				);
			},
			checkoutCart: (context) =>
				context.data
					? requestCheckoutApi('checkoutCart', {
							cart: context.data,
							getCodes: true,
						})
					: Promise.reject({ error: 'Cart is null' }),
			copyCart: (context, _event) =>
				context.cartId ? copyCart(context.cartId) : Promise.reject(),
			getCartWithPost: () => requestCheckoutApi('fetchCart'),
			getCartWithId: (context, _event) =>
				requestCheckoutApi('fetchCart', { id: context.cartId }),
			verifyPayment: (context) =>
				context.cartId
					? verifyRedirectPayment(
							context.cartId,
							context.verifyPaymentData?.paymentDetails,
						)
					: Promise.reject(),
			waitForUserActor: (context) =>
				waitForUserInfoActor(context.userInfoActor),
		},
		delays: {
			RETRY_DELAY: (context) => (context.failedLoadingRetries || 1) * 2000,
		},
	},
);

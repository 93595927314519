/**
 * Link
 */

import React, {
	type AnchorHTMLAttributes,
	type ReactNode,
	type RefAttributes,
} from 'react';
import clsx from 'clsx';
import NextLink, { type LinkProps as NextLinkProps } from 'next/link';

import ScrollLink from './ScrollLink';

export interface Props
	extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
		NextLinkProps,
		RefAttributes<HTMLAnchorElement> {
	/** Element ID, is appended to `href` with #. */
	anchor?: string;

	/** Link text/content. */
	children?: ReactNode;

	/** Link class names. */
	className?: string;

	/** Stretch the link to cover everything to its nearest relative ancestor. */
	cover?: boolean;

	/** Hide the link from screen readers. */
	isScreenReaderHidden?: boolean;

	/** Underline the text. */
	underline?: boolean;
}

/** Wrapped Next.js Link */
const Link = React.forwardRef<HTMLAnchorElement, Props>(
	(
		{
			anchor,
			children,
			className,
			cover = false,
			href: rawHref,
			isScreenReaderHidden = false,
			target,
			underline = false,

			// Extract Next link props to not pass them to ScrollLink
			legacyBehavior,
			passHref,
			prefetch = false,
			replace,
			scroll,
			shallow,
			...props
		},
		ref,
	) => {
		let href = rawHref.toString();
		if (anchor) {
			// Let the anchor prop override any href anchors.
			href = `${href.split('#')[0]}#${anchor.replace('#', '')}`;
		}

		const commonProps: AnchorHTMLAttributes<HTMLAnchorElement> = {
			'aria-hidden': isScreenReaderHidden ? true : props['aria-hidden'],
			'tabIndex': isScreenReaderHidden ? -1 : props.tabIndex,
			'className':
				clsx(
					className,
					underline && 'underline hover:no-underline',
					cover && 'before:absolute before:inset-0 before:z-2',
				) || undefined,
		};
		const nextProps = {
			legacyBehavior,
			replace,
			scroll,
			shallow,
			passHref,
			prefetch,
		};

		// legacyBehavior requires <a> children, sending that to ScrollLink will
		// lead to nested tags.
		if (href.startsWith('#') && !legacyBehavior) {
			// Don't include target here since we should stay on the same page.
			return (
				<ScrollLink {...props} {...commonProps} ref={ref} anchor={href}>
					{children}
				</ScrollLink>
			);
		}

		return (
			<NextLink
				{...props}
				{...commonProps}
				{...nextProps}
				ref={ref}
				href={href}
				target={target}
			>
				{children}
			</NextLink>
		);
	},
);
Link.displayName = 'Link';

export default Link;

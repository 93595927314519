import { type EffectCallback, useEffect } from 'react';

import { is } from 'utils/helpers';

import { usePrevious } from './usePrevious';

/**
 * Run an effect when a value changes.
 *
 * @example
 *
 * function Component({ someProp }) {
 *   useValueChangeEffect(someProp, () => {
 *     // Runs when the someProp value changes
 *   });
 *
 *   return null;
 * }
 */
export function useValueChangeEffect<T>(
	value: T,
	effect: (previousValue: T, currentValue: T) => ReturnType<EffectCallback>,
) {
	const prevValue = usePrevious(value);
	useEffect(() => {
		if (!is.undefined(prevValue) && prevValue !== value) {
			return effect(prevValue, value);
		}
		return undefined;
		// `effect` will change every render.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, prevValue]);
}

// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconShare(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M3 24a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1 1 1 0 0 1-1 1H4v14h16V8h-3a1 1 0 0 1-1-1 1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1z" />
			<path d="M10.999 11V3.586L9.878 4.707a1 1 0 0 1-1.414 0 1 1 0 0 1 0-1.414L11.293.464a1 1 0 0 1 1.414 0l2.829 2.828a1 1 0 0 1 0 1.415 1 1 0 0 1-1.415 0L13 3.585v7.414a1 1 0 0 1-1 1A1 1 0 0 1 10.999 11z" />
		</svg>
	);
}
IconShare.displayName = 'IconShare';

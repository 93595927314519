// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconVisibility(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M12 4.5A11.827 11.827 0 0 0 1 12a11.817 11.817 0 0 0 22 0 11.827 11.827 0 0 0-11-7.5zM12 17a5 5 0 1 1 5-5 5 5 0 0 1-5 5zm0-8a3 3 0 1 0 3 3 3 3 0 0 0-3-3z" />
		</svg>
	);
}
IconVisibility.displayName = 'IconVisibility';

// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconBankIdSe(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M4.52 22.77c.04-.21.16-.4.33-.55.4-.33 1.36-.38 1.74-.64.04-.3-.08-.4-.44-.4-.5.03-.98.22-1.36.55l.14-.94a2.6 2.6 0 0 1 1.6-.55c.74 0 1.4.3 1.27 1.11l-.14.91c-.05.32-.04.42.31.43l-.36.85c-.63.05-.94-.02-1.09-.3-.33.2-.71.32-1.1.31-.72 0-.96-.37-.9-.78Zm.97-.05-.01.07c0 .16.13.29.3.29.24-.01.46-.09.64-.22 0-.1 0-.2.02-.3l.07-.45c-.34.21-.95.17-1.02.61Zm13.47.78.64-4.14h2.08l.2-.01a1.77 1.77 0 0 1 1.71 2.13 2.3 2.3 0 0 1-2.3 2.02h-2.33Zm1.6-.98h.48c.6 0 1.1-.49 1.1-1.09.1-.55-.07-1.09-.85-1.09h-.4l-.33 2.18Zm-3.76.98.64-4.14h1.45l-.64 4.14H16.8Zm-2 0-.96-1.48-.24 1.48h-1.26l.64-4.14h1.26l-.31 2.04 1.2-1.1h1.57l-1.56 1.36 1.25 1.84H14.8Zm-4.38 0 .27-1.75c.05-.33-.04-.47-.3-.47a.7.7 0 0 0-.55.34l-.29 1.88H8.3l.5-3.2h1.26l-.06.41c.29-.3.68-.46 1.1-.47.7 0 1.03.43.92 1.14l-.32 2.12h-1.27ZM0 23.5l.64-4.14h2.42c1.03 0 1.29.52 1.21 1-.08.4-.38.74-.78.86a.93.93 0 0 1 .72 1.1c-.12.7-.75 1.2-1.46 1.18H0Zm1.55-.63h.44c.48 0 .77-.2.83-.6.05-.35-.15-.56-.62-.56h-.47l-.18 1.16Zm.28-1.8h.41c.36.06.69-.19.74-.54.05-.31-.1-.54-.57-.54H2l-.16 1.09Zm11.44-3.9.76-4.88c1.94-.02 3.56-.93 3.9-3.05.04-.26.06-.52.06-.78 0-1.72-1.1-2.47-2.97-2.49h-1.56c-.4 0-.76-.2-.95-.53a.43.43 0 0 1-.02-.15c0-.16.08-.43.5-.72.31-.2.56-.5.69-.86l.02-.2a.9.9 0 0 0-.29-.66 1.86 1.86 0 0 0-1.28-.38c-.77-.07-1.5.39-1.77 1.12l-.01.12c.01.34.2.65.48.83.16.1.27.28.29.48 0 .11-.04.22-.1.32-.31.42-.8.65-1.32.63H8.67l.81-5.1h6.04C20.42.86 24 2.78 24 7.51c0 .58-.05 1.17-.14 1.75a9.27 9.27 0 0 1-9.46 7.91h-1.13Zm-12.3 0C1.06 16.45 2.55 7 2.64 6.4h1.1a2 2 0 0 0 1.67-.83.98.98 0 0 0-.3-1.37.61.61 0 0 1-.3-.54c.24-.52.79-.82 1.35-.76.36-.04.71.06 1 .27.13.11.18.29.14.46-.1.25-.29.47-.53.6-.83.59-.68 1.2-.62 1.37.25.5.78.81 1.34.8h1.07l-1 6.31h.02l-.7 4.48H.96Z" />
		</svg>
	);
}
IconBankIdSe.displayName = 'IconBankIdSe';

// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconLocalShipping(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M19.5 8H17V6a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v9c0 1.1.9 2 2 2 0 1.65 1.35 3 3 3s3-1.35 3-3h6c0 1.65 1.35 3 3 3s3-1.35 3-3h1a1 1 0 0 0 1-1v-3.33c0-.43-.14-.85-.4-1.2L20.3 8.4c-.19-.25-.49-.4-.8-.4ZM6 18a1 1 0 0 1-1-1 1 1 0 0 1 1-1 1 1 0 0 1 1 1 1 1 0 0 1-1 1Zm13.5-8.5 1.96 2.5H17V9.5h2.5ZM18 18a1 1 0 0 1-1-1 1 1 0 0 1 1-1 1 1 0 0 1 1 1 1 1 0 0 1-1 1Z" />
		</svg>
	);
}
IconLocalShipping.displayName = 'IconLocalShipping';

import { ApiJulaModelsCartJulaVariantModel, WishlistVariant } from 'models/api';
import { Product } from 'models/product';
import { AccessoryCard, ProductCard } from 'models/productCard';

export const checkSupercheap = (
	product:
		| ProductCard
		| AccessoryCard
		| Product
		| ApiJulaModelsCartJulaVariantModel
		| WishlistVariant
		| undefined,
) => {
	if (!product) {
		return false;
	}
	if ('salesAttributes' in product) {
		return Boolean(product.salesAttributes?.isHero);
	}
	if ('isHero' in product) {
		return product.isHero;
	}
	return false;
};

// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconFire(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M19.48 12.35c-1.57-4.08-7.16-4.3-5.81-10.23a.5.5 0 0 0-.75-.55 9.33 9.33 0 0 0-4.05 12.05.5.5 0 0 1-.75.59 4.979 4.979 0 0 1-1.84-4.75.5.5 0 0 0-.91-.34A9.4 9.4 0 0 0 4 14.37a8.053 8.053 0 0 0 6.81 7.54 8.578 8.578 0 0 0 6.95-1.87 6.914 6.914 0 0 0 1.72-7.69zm-9.28 5.03a3.09 3.09 0 0 0 2.38-2.31c.33-1.43-.96-2.83-.09-5.09.33 1.87 3.27 3.04 3.27 5.08.08 2.53-2.66 4.7-5.56 2.32z" />
		</svg>
	);
}
IconFire.displayName = 'IconFire';
